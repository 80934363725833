import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import FooterHomePage from '../../components/FooterHomePage';
import headerImage from '../../img/Home-page/header.png';
import menuBtn from '../../img/Home-page/burger_menu_btn.svg';
import alyona from '../../img/Home-page/alyona.jpg';
import vika from '../../img/Home-page/vika.jpg';
import zina from '../../img/Home-page/zina.jpg';
import star from '../../img/Home-page/slider_star.svg';
import advantage from '../../img/Home-page/advantage.svg';
import money from '../../img/Home-page/money.svg';
import heart from '../../img/Home-page/heart.svg';
import people from '../../img/Home-page/people.svg';
import hands from '../../img/Home-page/hands.svg';
import LinkButton from '../../components/Talent/LinkButton';
import { observer } from 'mobx-react-lite';
// import { useStore } from '../../hooks/useRootStore';

import styles from './homePage.module.scss';

const sliderTalentArray = [
  { imgTalent: alyona, imgStar: star, top: '', name: 'Alena', profession: 'Dancer', citizenship: 'Russia' },
  { imgTalent: vika, imgStar: star, top: 'Top', name: 'Vika', profession: 'Photo model', citizenship: 'Bulgaria' },
  { imgTalent: zina, imgStar: star, top: '', name: 'Zina', profession: 'Image model', citizenship: 'Kazakhstan' },
  { imgTalent: alyona, imgStar: star, top: '', name: 'Alena', profession: 'Dancer', citizenship: 'Russia' },
  { imgTalent: vika, imgStar: star, top: 'Top', name: 'Vika', profession: 'Photo model', citizenship: 'Bulgaria' },
  { imgTalent: zina, imgStar: star, top: '', name: 'Zina', profession: 'Image model', citizenship: 'Kazakhstan' },
];

const advantageArray = [
  {
    title: 'No hidden fees',
    desc: `We do not use hidden fees or automatic subscriptions.`,
    img: money,
    class: styles.yellow,
  },
  {
    title: 'Easy to search',
    desc: `Use filters and find any vacancy or candidate anywhere in the world`,
    img: heart,
    class: styles.lightPurple,
  },
  {
    title: 'No fakes or bots',
    desc: `Only real people and companies passed moderation.`,
    img: people,
    class: styles.turquoise,
  },
  {
    title: 'Safe professional dating',
    desc: `We make cooperation between users as convenient and secure as possible.`,
    img: hands,
    class: styles.pink,
  },
];

const HomePage: React.FC = observer(() => {
  /*const {
    homePageStore: { click, setClick },
  } = useStore();*/

  const [active, setActive] = useState('talent');

  const settings = {
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div className={styles.homePage}>
      <header className={styles.header}>
        <article>
          <Link className={styles.logoBtn} to='/'>
            <img width={140} height={26} src={headerImage} alt='Perfomx' />
            <span className={styles.subtitle}>Job in entertainment</span>
          </Link>
        </article>
        <Link to='/hot-bar'>
          <img width={32} height={32} src={menuBtn} alt='menu' />
        </Link>
      </header>
      <main className={styles.main}>
        <section className={styles.background}>
          <h2 className={styles.title}>
            Unlock Your Talent
            <br />
            Find Your
            <br />
            Dream Job
            <br />
            <span className={styles.selection}>Today!</span>
          </h2>
        </section>
        <section className={styles.slider}>
          <article className={active === 'talent' ? styles.switchBtnBox : styles.switch}>
            <button
              className={`${styles.switchBtn} ${active === 'jobs' && styles.switchBtnColor}`}
              type='button'
              onClick={() => setActive('jobs')}>
              Jobs
            </button>
            <button
              className={`${styles.switchBtn} ${active === 'talent' && styles.switchBtnColor}`}
              type='button'
              onClick={() => setActive('talent')}>
              Talent
            </button>
          </article>
          <article className={styles.sliderContainer}>
            <Slider {...settings} className={styles.sliderBox}>
              {sliderTalentArray.map((item, index) => (
                <figure className={styles.slideContainer} key={index}>
                  {item.top && <p className={styles.top}>{item.top}</p>}
                  <img className={styles.star} src={item.imgStar} alt='star' />
                  <img className={styles.sliderImg} src={item.imgTalent} width={120} height={120} alt={item.name} />
                  <div className={styles.descBox}>
                    <p className={styles.desc}>{item.name}</p>
                    <p className={styles.desc}>{item.profession}</p>
                  </div>
                  <figcaption className={styles.sliderTitle}>{item.citizenship}</figcaption>
                </figure>
              ))}
            </Slider>
          </article>
          <p className={styles.joinDesc}>Join and find job in different countries directly from employers!</p>
          <LinkButton
            title='Join us'
            link={active === 'talent' ? '/talent/create-profile-1' : '/employer/create-profile-1'}
          />
        </section>
        <section className={styles.advantage}>
          <img width={297} height={81} src={advantage} alt='advantage' />
          <article className={styles.sliderContainer}>
            <Slider {...settings} className={styles.advantageSliderBox}>
              {advantageArray.map((item, index) => (
                <figure className={`${styles.advantageContainer} ${item.class}`} key={index}>
                  <figcaption className={styles.advantageTitle}>{item.title}</figcaption>
                  <p className={styles.advantageDesc}>{item.desc}</p>
                  <img className={styles.advantageSliderImg} src={item.img} width={120} height={120} alt={item.title} />
                </figure>
              ))}
            </Slider>
          </article>
        </section>
        <section className={styles.collaboration}>
          <h3 className={styles.perfomTitle}>PerfomX - collaboration that leads to success</h3>
          <p className={styles.perfomDesc}>
            PerfomX is a platform for finding highly paid jobs in the entertainment industry worldwide, both for
            employers looking for talent and for job seekers.
          </p>
          <p className={styles.perfomDesc}>
            We create convenient and reliable relationships, value transparency, and conduct strict moderation to
            eliminate fakes and scammers.
          </p>
          <p className={styles.perfomDesc}>
            Payment for services is only made after your approval, with no hidden fees.
          </p>
          <p className={styles.perfomDesc}>Join PerfomX and change your life for the better!</p>
        </section>
      </main>
      <FooterHomePage footer='homePage' />
    </div>
  );
});

export default HomePage;
