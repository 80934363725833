import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../../../components/Talent/ProgressBar';
import CreateProfileNavigation from '../../../components/Talent/CreateProfileNavigation/CreateProfileNavigation';
import passportImg from '../../../img/Ctrate-form/passport_verification.svg';
import defaultPhoto from '../../../img/Ctrate-form/example_of_ a_passport.jpg';
import cameraUploaded from '../../../img/Ctrate-form/camera_uploaded.svg';
import crossClose from '../../../img/Ctrate-form/cross-close.svg';

import styles from './createProfilePassport.module.scss';

const CreateProfilePassport = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log(data);
    navigate('/talent/congratulations-job-application');
    /*try {
      const formData = new FormData();
      formData.append('photo', data.photo[0]);

      const response = await fetch('/api/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log('Photo uploaded successfully');
      } else {
        console.error('Upload failed');
      }
    } catch (error) {
      console.error('Error uploading photo', error);
    }*/
  };

  const handlePhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedPhoto(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handlePhotoRemove = () => {
    setUploadedPhoto(null);
  };

  return (
    <section className={styles.createProfileVerification}>
      <ProgressBar activeSteps={5} formSteps={5} />
      <img className={styles.img} width={231} height={77} src={passportImg} alt='Create profile' />
      <p className={styles.desc}>
        Obtain the status 'Passport confirmed' to verify your identity, increase trust, and improve your chances of
        being chosen. (Optional)
      </p>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <label className={styles.uploadPhoto} htmlFor='photoInput'>
          <img width={240} height={170} src={cameraUploaded} alt='camera uploaded' />
        </label>
        <p className={styles.desc}>
          Please note that a copy of your passport will be securely stored and will not be accessible to third parties.
          This procedure is only necessary to confirm your intentions and personal data
        </p>
        <input
          id='photoInput'
          type='file'
          accept='image/*'
          {...register('photo', { required: true })}
          onChange={handlePhotoChange}
          style={{ display: 'none' }}
        />
        <article className={styles.imgBox}>
          <img
            width={250}
            height={166}
            className={styles.uploadedPhoto}
            src={uploadedPhoto || defaultPhoto}
            alt='uploaded photo'
          />
          <button type='button' className={styles.removeButton} onClick={handlePhotoRemove}>
            <img src={crossClose} alt='cross close' />
          </button>
          {errors.photo && <span className={styles.errorMessage}>Please upload a photo</span>}
        </article>
        <p className={styles.desc}>Attach a copy of your passport as shown in the example</p>
        <p className={styles.descStatus}>The status 'Passport confirmed' will be displayed on your profile</p>
        <CreateProfileNavigation backLink='/talent/create-profile-2' />
      </form>
    </section>
  );
};

export default CreateProfilePassport;
