import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Talent/Footer';
import { mocDataSimilarTalent } from '../ViewProfile/mocDataSimilarTalent';
import backArrow from '../../../img/Boost-profile/back_arrow.svg';
import boostProfile from '../../../img/Boost-profile/boost_profile.svg';
import groupImg from '../../../img/Vacancies/group.svg';
import favoritesImg from '../../../img/Vacancies/favorites.svg';
import noFavoritesImg from '../../../img/Vacancies/no_favorites.svg';
import noPhoto from '../../../img/Profile/no_photo.svg';

import styles from './becomeTopTalent.module.scss';

const tariffsArray = [
  { desc: '7 days', subTitle: 28 },
  { desc: '1 month', subTitle: 88 },
  { desc: '14 days', subTitle: 48 },
];

const BecomeTopTalent = () => {
  return (
    <div className={styles.becomeTopTalent}>
      <header className={styles.header}>
        <Link className={styles.link} to='/talent/profile'>
          <img width={32} height={32} src={backArrow} alt='back arrow' />
        </Link>
        <img className={styles.img} width={231} height={69} src={boostProfile} alt='back arrow' />
      </header>
      <main>
        <p className={styles.desc}>TOP Talant are displayed first in search results, followed by others</p>
        <section className={styles.topTalentContainer}>
          <img src={noPhoto} alt='no photo' />
          {mocDataSimilarTalent.slice(0, 3).map((item, index) => (
            <div className={styles.vacanciesContainer} key={index}>
              {item.top && <p className={styles.top}>{item.top}</p>}
              {item.applicants > 0 && (
                <div className={styles.group}>
                  <img width={16} height={16} src={groupImg} alt='group' />
                  <p className={styles.groupDesc}>{item.applicants}</p>
                </div>
              )}
              <img
                className={styles.favorites}
                width={24}
                height={24}
                src={item.favorites ? favoritesImg : noFavoritesImg}
                alt='favorites'
              />
              <img
                className={styles.vacanciesImg}
                width={171}
                height={171}
                src={item.photoPlaceWork[0].url}
                alt='house'
              />
            </div>
          ))}
        </section>
        <p className={styles.desc}>
          <Link className={styles.improveLink} to='/'>
            Improve your profile
          </Link>{' '}
          with TOP enabled and become first in search results.
        </p>
        <h2 className={styles.title}>Choose a plan</h2>
        <section className={styles.tariffs}>
          {tariffsArray.map((item, index) => (
            <div className={styles.tariffsBox} key={index}>
              <p className={styles.tariffsDesc}>{item.desc}</p>
              <p className={styles.tariffsSubTitle}>$ {item.subTitle}</p>
            </div>
          ))}
        </section>
        <button className={styles.balance} type='button'>
          Top up balance
        </button>
        <p className={styles.funds}>Insufficient funds for purchase. Balance: 0</p>
      </main>

      <Footer />
    </div>
  );
};

export default BecomeTopTalent;
