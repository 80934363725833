import house from '../../../img/Vacancies/house.jpg';
import club from '../../../img/Vacancies/club.jpg';
import club2 from '../../../img/Vacancies/club_2.jpg';
import club3 from '../../../img/Vacancies/club_3.jpg';
import hotel from '../../../img/Vacancies/hotel.jpg';
import recreationCenter from '../../../img/Vacancies/recreation_center.jpg';
import recreationCenter2 from '../../../img/Vacancies/recreation_center_2.jpg';
import yacht from '../../../img/Vacancies/yacht.jpg';
import yacht2 from '../../../img/Vacancies/yacht_2.jpg';
import talent1 from '../../../img/Profile/talent_1.jpg';
import talent2 from '../../../img/Profile/talent_2.jpg';
import talent3 from '../../../img/Profile/talent_3.jpg';

export const mocDataSimilarTalent = [
  {
    id: '1',
    top: 'Top',
    applicants: 1,
    favorites: true,
    photoPlaceWork: [
      { id: '123', url: talent1 },
      { id: '231', url: talent2 },
      { id: '321', url: talent3 },
    ],
  },
  {
    id: '2',
    top: '',
    applicants: 0,
    favorites: false,
    photoPlaceWork: [
      { id: '123', url: talent2 },
      { id: '231', url: talent1 },
      { id: '321', url: talent3 },
    ],
  },
  {
    id: '3',
    top: '',
    applicants: 147,
    favorites: false,
    photoPlaceWork: [
      { id: '123', url: talent3 },
      { id: '231', url: talent1 },
      { id: '321', url: talent2 },
    ],
  },
  {
    id: '4',
    top: 'Top',
    applicants: 5,
    favorites: true,
    photoPlaceWork: [
      { id: '123', url: talent1 },
      { id: '231', url: talent2 },
      { id: '321', url: talent3 },
    ],
  },
  {
    id: '5',
    top: 'Top',
    applicants: 0,
    favorites: true,
    photoPlaceWork: [
      { id: '123', url: talent2 },
      { id: '231', url: talent1 },
      { id: '321', url: talent3 },
    ],
  },
  {
    id: '6',
    top: 'Top',
    applicants: 7,
    favorites: true,
    photoPlaceWork: [
      { id: '123', url: talent3 },
      { id: '231', url: talent1 },
      { id: '321', url: talent2 },
    ],
  },
  {
    id: '7',
    top: 'Top',
    applicants: 122,
    favorites: true,
    photoPlaceWork: [
      { id: '123', url: talent1 },
      { id: '231', url: talent2 },
      { id: '321', url: talent3 },
    ],
  },
  {
    id: '8',
    top: 'Top',
    applicants: 18,
    favorites: true,
    photoPlaceWork: [
      { id: '123', url: talent2 },
      { id: '231', url: talent1 },
      { id: '321', url: talent3 },
    ],
  },
  {
    id: '9',
    top: 'Top',
    applicants: 7,
    favorites: true,
    photoPlaceWork: [
      { id: '123', url: talent3 },
      { id: '231', url: talent1 },
      { id: '321', url: talent2 },
    ],
  },
];
