import React from 'react';
import styles from './hotBarMenu.module.scss';
import hotBarLogo from '../../img/Home-page/hot_bar_logo.svg';
import { Link } from 'react-router-dom';

const HotBarMenu = () => {
  return (
    <section className={styles.hotBarMenu}>
      <nav className={styles.hotBar}>
        <Link to='/'>
          <img className={styles.hotBarLogo} width={211} height={57} src={hotBarLogo} alt='hot bar logo' />
        </Link>
        <Link className={styles.menuItem} to='/about-us'>
          About Us
        </Link>
        <Link className={styles.menuItem} to='/faq'>
          FAQ
        </Link>
        <Link className={styles.menuItem} to='/career'>
          Career
        </Link>
        <Link className={styles.menuItem} to='/contact'>
          Contacts
        </Link>
        <Link className={styles.menuItem} to='/privacy'>
          Privacy Policy
        </Link>
      </nav>
    </section>
  );
};

export default HotBarMenu;
