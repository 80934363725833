import React, { useState } from 'react';

import styles from './congratulationsJobApplication.module.scss';
import PopUpVerifyPhone from '../../../components/Talent/PopUpVerifyPhone';
import cross from '../../../img/Ctrate-form/cross_black.svg';
import { Link } from 'react-router-dom';

const reasonsArray = [
  { desc: 'Your profile is now available to employers' },
  {
    desc: 'Use filters to search for vacancies',
  },
  {
    desc: 'Communicate directly with employers',
  },
  {
    desc: 'Conclude contracts through our service',
  },
];

const detailsArray = [
  {
    important: 'Today,',
    desc: 'you are being offered a free trial period for 1 month, with access to exclusive features',
  },
  {
    important: 'In 23 days,',
    desc: ' you will receive a reminder via email or Telegram that your trial period will end in 7 days',
  },
  {
    important: 'In 1 month,',
    desc: ' the paid subscription period will begin based on the selected tariff',
  },
];

const CongratulationsJobApplication = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <section className={styles.congratulationsJobApplication}>
      <h1 className={styles.title}>Congratulations on successfully completing the job application</h1>
      <ul className={styles.welcomeItems}>
        {reasonsArray.map((item, index) => (
          <li className={styles.welcomeItem} key={index}>
            <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M2 6.36842L6.03333 11L13 3' stroke='#219653' strokeWidth='2.5' strokeLinecap='round' />
            </svg>
            <aside>
              <p className={styles.welcomeItemDesc}>{item.desc}</p>
            </aside>
          </li>
        ))}
      </ul>
      <h2 className={styles.subTitle}>Join PerfomX members who are using the Premium subscription!</h2>
      <button className={styles.openPopUpBtn} type='button' onClick={() => setPopupOpen(true)}>
        Try for 1 month for free
      </button>
      {popupOpen && (
        <article className={styles.popupPhone}>
          <div className={styles.popUpBg} />
          <div className={styles.popUpContainer}>
            <hr className={styles.topLine} />
            <h1 className={styles.titlePopup}>Details about your free trial subscription.</h1>
            <h2 className={styles.subtitle}>How it works:</h2>
            <ul className={styles.welcomeItems}>
              {detailsArray.map((item, index) => (
                <li className={styles.welcomeItem} key={index}>
                  <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M2 6.36842L6.03333 11L13 3' stroke='#219653' strokeWidth='2.5' strokeLinecap='round' />
                  </svg>
                  <p className={styles.welcomeItemDesc}>
                    <span>{item.important}</span> {item.desc}
                  </p>
                </li>
              ))}
            </ul>
            <h2 className={styles.subTitle}>1 month, the paid subscription period will commence according to the chosen plan</h2>
            <Link className={styles.sendBtn} to='/talent/jobs'>
              Try for 1 month for free
            </Link>
          </div>
        </article>
      )}
    </section>
  );
};

export default CongratulationsJobApplication;
