import house from "../../../img/Vacancies/house.jpg";
import club from "../../../img/Vacancies/club.jpg";
import club2 from "../../../img/Vacancies/club_2.jpg";
import club3 from "../../../img/Vacancies/club_3.jpg";
import hotel from "../../../img/Vacancies/hotel.jpg";
import recreationCenter from "../../../img/Vacancies/recreation_center.jpg";
import recreationCenter2 from "../../../img/Vacancies/recreation_center_2.jpg";
import yacht from "../../../img/Vacancies/yacht.jpg";
import yacht2 from "../../../img/Vacancies/yacht_2.jpg";

export const vacancies = [
  {
    id: '1',
    top: 'Top',
    applicants: 1,
    favorites: true,
    workplace: 'Club',
    salary: '1000',
    cities: 'Moscow',
    countryJobPosting: 'Russia',
    photoPlaceWork: [
      { id: '123', url: house },
      { id: '231', url: club },
      { id: '321', url: club2},
    ],
  },
  {
    id: '2',
    top: '',
    applicants: 0,
    favorites: false,
    workplace: 'Dancer',
    salary: '1500',
    cities: 'Dubai',
    countryJobPosting: 'UAE',
    photoPlaceWork: [
      { id: '123', url: club },
      { id: '231', url: house },
      { id: '321', url: club2},
    ],
  },
  {
    id: '3',
    top: '',
    applicants: 147,
    favorites: false,
    workplace: 'Escort',
    salary: '3000',
    cities: 'Moscow',
    countryJobPosting: 'Russia',
    photoPlaceWork: [
      { id: '123', url: club3 },
      { id: '231', url: club },
      { id: '321', url: club2},
    ],
  },
  {
    id: '4',
    top: 'Top',
    applicants: 5,
    favorites: true,
    workplace: 'Escort',
    salary: '700',
    cities: 'Dubai',
    countryJobPosting: 'UAE',
    photoPlaceWork: [
      { id: '123', url: hotel },
      { id: '231', url: club },
      { id: '321', url: club2},
    ],
  },
  {
    id: '5',
    top: 'Top',
    applicants: 0,
    favorites: true,
    workplace: 'Escort',
    salary: '1000',
    cities: 'Moscow',
    countryJobPosting: 'Russia',
    photoPlaceWork: [
      { id: '123', url: recreationCenter },
      { id: '231', url: club },
      { id: '321', url: club2},
    ],
  },
  {
    id: '6',
    top: 'Top',
    applicants: 7,
    favorites: true,
    workplace: 'Escort',
    salary: '1000',
    cities: 'Dubai',
    countryJobPosting: 'UAE',
    photoPlaceWork: [
      { id: '123', url: recreationCenter2 },
      { id: '231', url: club },
      { id: '321', url: club2},
    ],
  },
  {
    id: '7',
    top: 'Top',
    applicants: 122,
    favorites: true,
    workplace: 'Escort',
    salary: '500',
    cities: 'Moscow',
    countryJobPosting: 'Russia',
    photoPlaceWork: [
      { id: '123', url: recreationCenter },
      { id: '231', url: club },
      { id: '321', url: club2},
    ],
  },
  {
    id: '8',
    top: 'Top',
    applicants: 18,
    favorites: true,
    workplace: 'Escort',
    salary: '2500',
    cities: 'Dubai',
    countryJobPosting: 'UAE',
    photoPlaceWork: [
      { id: '123', url: yacht },
      { id: '231', url: club },
      { id: '321', url: club2},
    ],
  },
  {
    id: '9',
    top: 'Top',
    applicants: 7,
    favorites: true,
    workplace: 'Escort',
    salary: '1000',
    cities: 'Dubai',
    countryJobPosting: 'UAE',
    photoPlaceWork: [
      { id: '123', url: yacht2 },
      { id: '231', url: club },
      { id: '321', url: club2},
    ],
  },
];