import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import ProgressBarEmployer from '../../../components/Employer/ProgressBarEmployer';
import CreateProfileNavigation from '../../../components/Talent/CreateProfileNavigation/CreateProfileNavigation';
import verificationImg from '../../../img/Create-form-employer/verification.svg';
import defaultPhoto from '../../../img/Ctrate-form/default-photo_2.jpg';
import crossClose from '../../../img/Ctrate-form/cross-close.svg';

import styles from './verification.module.scss';

const Verification = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [uploadedPhotos, setUploadedPhotos] = useState(Array(8).fill(null));
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log(data);
    navigate('/employer/welcome-to-perfomx');
    // navigate('/talent/congratulations-job-application');
  };

  const handlePhotoChange = (index) => (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newPhotos = [...uploadedPhotos];
        newPhotos[index] = e.target.result;
        setUploadedPhotos(newPhotos);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handlePhotoRemove = (index) => () => {
    const newPhotos = [...uploadedPhotos];
    newPhotos[index] = null;
    setUploadedPhotos(newPhotos);
  };

  return (
    <section className={styles.verification}>
      <ProgressBarEmployer activeSteps={4} formSteps={4} />
      <img className={styles.img} width={233} height={88} src={verificationImg} alt='verification' />
      <p className={styles.desc}>
        Get the status <span>"Documents Verified"</span>   Increase trust in yourbusiness. Upload copies of documents,
        such as patents and certificates registration, on our service. (Not required)
      </p>

      <Link to='/employer/create-profile-3/photo-placement-rules' className={`${styles.title} ${styles.important}`}>
        Photo Placement Rules
      </Link>
      <h3 className={styles.title}>Post photos and videos:</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className={styles.formContainer}>
          {uploadedPhotos.map((photo, index) => (
            <li key={index} className={styles.imgBox}>
              <label className={styles.uploadPhoto} htmlFor={`photoInput-${index}`}>
                <img width={80} height={80} src={photo || defaultPhoto} alt={`uploaded photo ${index + 1}`} />
              </label>
              {photo && (
                <button type='button' className={styles.removeButton} onClick={handlePhotoRemove(index)}>
                  <img width={16} height={16} src={crossClose} alt='cross close' />
                </button>
              )}
              <input
                id={`photoInput-${index}`}
                type='file'
                accept='image/*'
                {...register(`photo${index}`, { required: index === 0 })}
                onChange={handlePhotoChange(index)}
                style={{ display: 'none' }}
              />
              {errors[`photo${index}`] && <span className={styles.errorMessage}>Please upload a photo</span>}
            </li>
          ))}
        </ul>
        <p className={styles.desc}>
          Please note that a copy of your documents will be stored securely and will not be accessible to third parties.
          The procedure is necessary only to confirm your intentions and the authenticity of the company
        </p>
        <p className={styles.status}>The status "Documents Verified" will be displayed in your profile</p>
        <CreateProfileNavigation backLink='/talent/create-profile-3' />
      </form>
    </section>
  );
};

export default Verification;
