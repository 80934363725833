import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import jobs from '../../../img/Footer/job.svg';
import jobActive from '../../../img/Footer/job_active.svg';
import jobAlert from '../../../img/Footer/job_alert.svg';
import jobAlertActive from '../../../img/Footer/job_alert_active.svg';
import apply from '../../../img/Footer/apply.svg';
import applyActive from '../../../img/Footer/apply_active.svg';
import chat from '../../../img/Footer/chat.svg';
import chatActive from '../../../img/Footer/chat_active.svg';
import profile from '../../../img/Footer/profile.svg';
import profileActive from '../../../img/Footer/profile_active.svg';

import styles from './footer.module.scss';

const menuFooterArray = [
  { desc: 'Jobs', img: jobs, imgActive: jobActive, link: '/talent/jobs' },
  { desc: 'JobAlert', img: jobAlert, imgActive: jobAlertActive, link: '/talent/job-alert' },
  { desc: 'Apply', img: apply, imgActive: applyActive, link: '/talent/apply' },
  { desc: 'Chat', img: chat, imgActive: chatActive, link: '/talent/chat' },
  { desc: 'Profile', img: profile, imgActive: profileActive, link: '/talent/profile' },
];

const routeToDescMap = {
  '/talent/jobs': 'Jobs',
  '/talent/jobs/': 'Jobs',
  '/talent/job-alert': 'JobAlert',
  '/talent/apply': 'Apply',
  '/talent/chat': 'Chat',
  '/talent/profile': 'Profile',
  '/talent/view-profile': 'Profile',
  '/talent/boost-profile': 'Profile',
  '/talent/become-a-top-talent': 'Profile',
  '/talent/receive-notifications': 'Profile',
};

const Footer = () => {
  const { pathname } = useLocation();

  // routeToDescMap[pathname] - если ключь pathname созпадает с ключом /vacancies, то возращается значение - Jobs
  const getActiveItem = (desc) => {
    if (desc === 'Jobs' && pathname.startsWith('/talent/jobs/')) {
      return true;
    }
    return routeToDescMap[pathname] === desc;
  };

  return (
    <footer className={styles.footer}>
      <nav className={styles.footerContainer}>
        {menuFooterArray.map(({ desc, img, imgActive, link }, index) => {
          const isActive = getActiveItem(desc);
          return (
            <Link className={styles.box} to={link} key={index}>
              <img width={30} height={28} src={isActive ? imgActive : img} alt={desc} />
              <span className={isActive ? styles.descActive : styles.desc}>{desc}</span>
            </Link>
          );
        })}
      </nav>
    </footer>
  );
};

export default Footer;
