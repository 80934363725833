import React, { useState } from 'react';
import styles from './popUpCreateProfile.module.scss';

const PopUpCreateProfile = ({ title, popupOpen, setPopupOpen, selectedObject, setSelectedObject, itemArray }) => {
  const [otherInput, setOtherInput] = useState('');

  const handleCountrySelect = (country: string) => {
    setOtherInput('');
    setSelectedObject(country);
  };

  const handleSelectButton = () => {
    if (otherInput.trim() !== '') {
      setSelectedObject(otherInput);
    }
    setPopupOpen(false);
  };

  // create-profile-1
  // filter
  return (
    popupOpen && (
      <article className={styles.popupCitizenship}>
        <div className={styles.popUpBg} />
        <div className={styles.popUpContainer}>
          <h2 className={styles.titlePopup}>{title}</h2>
          {(title === 'Countries' || title === 'Cities') && (
            <input type='text' className={styles.other} placeholder='Search:' />
          )}
          <ul className={styles.popUpList}>
            {itemArray.map((country, index) => (
              <li className={styles.itemPopUpu} key={index}>
                <input
                  type='radio'
                  id={`popup-input-${index}`}
                  name='citizenship'
                  value={country}
                  checked={selectedObject === country}
                  onChange={() => handleCountrySelect(country)}
                />
                <label
                  className={`${styles.label} ${selectedObject === country && styles.select}`}
                  htmlFor={`popup-input-${index}`}>
                  {country}
                </label>
              </li>
            ))}
          </ul>
          <hr className={styles.line} />

          {title !== 'Appearance' &&
            title !== 'Desired country for work' &&
            title !== 'Expected Salary' &&
            title !== 'Contract duration' &&
            title !== 'The company provides' &&
            title !== 'Accommodation' &&
            title !== 'Meals' &&
            title !== 'Cuisine' &&
            title !== 'Payment Schedule' && (
              <input
                type='text'
                className={styles.other}
                placeholder='Other:'
                value={otherInput}
                onChange={(e) => setOtherInput(e.target.value)}
              />
            )}

          <aside className={styles.popUpBtnBox}>
            <button
              className={styles.selectBtn}
              onClick={() => {
                setPopupOpen(false);
                handleCountrySelect('');
              }}
              type='button'>
              Cancel
            </button>
            <button className={styles.selectBtn} onClick={handleSelectButton} type='button'>
              Select
            </button>
          </aside>
        </div>
      </article>
    )
  );
};

export default PopUpCreateProfile;
