import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from '../../../components/Talent/Footer';
import club from '../../../img/Jobs/club.jpg';
import club2 from '../../../img/Jobs/club_2.jpg';
import club3 from '../../../img/Jobs/club_3.jpg';
import backArrow from '../../../img/Jobs/back_arrow.svg';
import star from '../../../img/Jobs/star.svg';
import starActive from '../../../img/Jobs/star_active.svg';
import employerIcon from '../../../img/Jobs/employer_icon.jpg';
import { employerProfileObject } from './employerProfileArray';

import styles from './jobs.module.scss';

const jobsArray = [club, club2, club3, club, club2, club3, club, club2];

const Jobs = () => {
  const [open, setOpen] = useState(false);
  const [applyForJob, setApplyForJob] = useState(false);

  const settings = {
    autoplay: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    dotsClass: styles.dots,
  };

  function generatePath(employerName) {
    return `/${employerName.toLowerCase().replace(/\s+/g, '-')}`;
  }

  // const path = generatePath(employerProfileObject.employerName);
  // console.log(path);

  return (
    <div className={styles.jobs}>
      <header className={styles.header}>
        <nav className={styles.navBox}>
          <Link to='/talent/jobs'>
            <img width={32} height={32} src={backArrow} alt='back arrow' />
          </Link>
          {employerProfileObject.favoriteEmployer ? (
            <img width={32} height={32} src={starActive} alt='star active' />
          ) : (
            <img width={32} height={32} src={star} alt='star' />
          )}
        </nav>
        <Slider {...settings} className={styles.sliderBox}>
          {jobsArray.map((item, index) => (
            <img width={390} height={362} src={item} alt='club.jpg' key={index} />
          ))}
        </Slider>
      </header>
      <main className={styles.main}>
        <Link className={styles.employerLink} to={`/employer${generatePath(employerProfileObject.businessName)}`}>
          <img width={60} height={60} src={employerIcon} alt='Employer icon' />
          <span className={styles.employerBox}>
            <span className={styles.employerTitle}>
              <span className={styles.employerTitleBolt}>{employerProfileObject.businessName} /</span> Agensy
            </span>
            <span className={styles.desc}>Turkey, Istanbul</span>
            <span className={`${styles.desc} ${styles.underline}`}>View profile</span>
          </span>
        </Link>
        <hr className={styles.line} />
        <h2 className={styles.title}>Jobs:</h2>
        <ul className={styles.box}>
          <li className={styles.desc}>
            Place of Work: <span className={styles.highlighted}>Club Space</span>
          </li>
          <li className={styles.desc}>
            Location: <span className={styles.highlighted}>Turkey, Istanbul</span>
          </li>
          <li className={styles.desc}>
            Location: <span className={styles.highlighted}>Vacancy: Go-go dancer</span>
          </li>
        </ul>
        <h2 className={styles.title}>Contract terms:</h2>
        <ul className={styles.box}>
          <li className={styles.desc}>
            Salary: <span className={styles.highlighted}>1500$</span>
          </li>
          <li className={styles.desc}>
            Salary bonuses: <span className={styles.highlighted}>% For cocktails and table dances</span>
          </li>
          <li className={styles.desc}>
            Working hours: <span className={styles.highlighted}> From 8:00 pm to 3:00 am</span>
          </li>
          <li className={styles.desc}>
            Break: <span className={styles.highlighted}> No break</span>
          </li>
          <li className={styles.desc}>
            Day off: <span className={styles.highlighted}>Once a week</span>
          </li>
          <li className={styles.desc}>
            Contract start: <span className={styles.highlighted}>From 26.03.24</span>
          </li>
          <li className={styles.desc}>
            Contract duration: <span className={styles.highlighted}>From 6 months</span>
          </li>
        </ul>
        <h2 className={styles.title}>Provided conditions:</h2>
        <ul className={styles.box}>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Air ticket</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Airport pickup</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Visa</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Transfer to and from work</span>
          </li>
          <li className={styles.desc}>
            Accommodation: <span className={styles.highlighted}> For 2 people</span>
          </li>
          <li className={styles.desc}>
            Meals: <span className={styles.highlighted}>Once a day</span>
          </li>
          <li className={styles.desc}>
            Cuisine: <span className={styles.highlighted}>Asian</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Costumes/Uniform</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Gym</span>
          </li>
          <li className={styles.desc}>
            <span className={styles.highlighted}>Professional training</span>
          </li>
        </ul>
        <h2 className={styles.title}>Additional information:</h2>
        <article className={`${styles.text} ${open && styles.textOpen}`}>
          <p className={`${open ? styles.textBox : styles.ellipsis} ${styles.desc}`}>
            SV Models is a dynamically growing agency dedicated to discovering and promoting talented models in the
            fashion and advertising industries. We build strong connections between models and clients, helping our
            talents to forge successful careers on a global scale. Our team consists of experts with years of experience
            who deeply understand the specifics of the industry and can offer a personalized approach to each
            individual. At SV Models, we strive to help every model unlock their potential and achieve success in their
            chosen field. We take pride in helping our models become the faces of leading brands and participate in the
            most prestigious projects.
          </p>
        </article>
        <button className={`${styles.expand}`} onClick={() => setOpen(!open)} type='button'>
          {open ? 'Hide' : 'Expand'}
        </button>
        <div className={styles.btnBox}>
          <Link className={styles.chatBtn} to='/chat'>
            Chat
          </Link>
          <button className={styles.apply} type='button' onClick={() => setApplyForJob(!applyForJob)}>
            Apply for job
          </button>
        </div>
      </main>
      <Footer />
      {applyForJob && (
        <section className={styles.shareProfilePopUp}>
          <article className={styles.profilePopUp}>
            <h4 className={styles.profilePopUpTitle}>Share your profile with an employer</h4>
            <hr className={styles.line} />
            <div className={styles.profilePopUpBtnBox}>
              <button className={styles.profilePopUpBtn} type='button' onClick={() => setApplyForJob(!applyForJob)}>
                Cancel
              </button>
              <button className={styles.profilePopUpBtn} type='button' onClick={() => setApplyForJob(!applyForJob)}>
                Share
              </button>
            </div>
          </article>
        </section>
      )}
    </div>
  );
};

export default Jobs;
