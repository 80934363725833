import React from 'react';
import styles from './progressBar.module.scss';

const ProgressBar = ({ activeSteps, formSteps }) => {
  return (
    <ul className={styles.progressBar}>
      {Array.from(Array(formSteps)).map((_, index) => (
        <li key={index} className={`${styles.line} ${index < activeSteps ? styles.active : ''}`} />
      ))}
    </ul>
  );
};

export default ProgressBar;
