import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import styles from './createProfile.module.scss';
import ProgressBar from '../../../components/Talent/ProgressBar';
import createFormImg from '../../../img/Ctrate-form/create-form.svg';
import arrow from '../../../img/Ctrate-form/arrow-down.svg';
import PopUpCreateProfile from '../../../components/Talent/PopUpCreateProfile';
import PopUpVerifyPhone from '../../../components/Talent/PopUpVerifyPhone';
import CreateProfileNavigation from "../../../components/Talent/CreateProfileNavigation/CreateProfileNavigation";

interface FormInputs {
  name: string;
  citizenship: string;
  profession: string;
  phoneNumber: string;
  // email: string;
  termsAccepted: boolean;
}

const CreateProfile: React.FC = () => {
  // Open/close popUp
  const [isCitizenshipPopupOpen, setIsCitizenshipPopupOpen] = useState(false);
  const [isProfessionPopupOpen, setIsProfessionPopupOpen] = useState(false);
  const [isVerifyPhonePopupOpen, setIsVerifyPhonePopupOpen] = useState(false);

  // Select object
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedProfession, setSelectedProfession] = useState('');
  const [selectedPhone, setSelectedPhone] = useState('');

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormInputs>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    console.log(data);
    navigate('/talent/welcome-to-perfomx');
  };

  const handleCitizenshipClick = () => {
    setIsCitizenshipPopupOpen(true);
  };

  const handleProfessionClick = () => {
    setIsProfessionPopupOpen(true);
  };

  const handleVerifyPhoneClick = () => {
    setIsVerifyPhonePopupOpen(true);
  };

  const countries = ['Ukraine', 'Russia', 'Belarus', 'Moldova', 'Kazakhstan', 'Georgia', 'Uzbekistan'];
  const profession = [
    'Go-go dancer',
    'A dancing group',
    'Choreographer',
    'Strip dancer',
    'Photo model',
    'KTV model',
    'Image model',
    'Crypto model',
    'Hostess',
    'Consummation',
    'Aerialist',
    'Dj',
    'Vocalist',
    'Musician',
    'Animators',
    'Translator',
  ];

  return (
    <section className={styles.createProfile}>
      <ProgressBar activeSteps={1} formSteps={5} />
      <img className={styles.img} width={231} height={77} src={createFormImg} alt='Create profile' />
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <input
          className={styles.createProfileInput}
          placeholder='Name'
          {...register('name', { required: 'Name is required' })}
        />
        {errors.name && <p className={styles.errorMessage}>{errors.name.message}</p>}

        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder='Citizenship'
            value={selectedCountry}
            onClick={handleCitizenshipClick}
            readOnly
            {...register('citizenship', { required: 'Citizenship is required' })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.citizenship && <p className={styles.errorMessage}>{errors.citizenship.message}</p>}

        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder='Profession'
            value={selectedProfession}
            onClick={handleProfessionClick}
            readOnly
            {...register('profession', { required: 'Profession is required' })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.profession && <p className={styles.errorMessage}>{errors.profession.message}</p>}

        <input
          className={styles.createProfileInput}
          type='number'
          placeholder='Phone number'
          onClick={handleVerifyPhoneClick}
          {...register('phoneNumber', { required: 'Phone number is required' })}
        />
        {errors.phoneNumber && <p className={styles.errorMessage}>{errors.phoneNumber.message}</p>}

        {/*<input
          type='email'
          className={styles.createProfileInput}
          placeholder='e-mail'
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid email address',
            },
          })}
        />
        {errors.email && <p className={styles.errorMessage}>{errors.email.message}</p>}*/}
        <p className={styles.createProfileDesc}>The number and email will not be displayed on the profile page</p>
        <aside className={styles.termsAcceptedContainer}>
          <label className={styles.checkBox}>
            <input type='checkbox' {...register('termsAccepted', { required: 'You must agree to the terms' })} />
            <span className={styles.createProfileDesc}>
            Yes, I understand and agree to{' '}
              <Link className={styles.privacyLink} to='/'>
              PerformX's Terms of Service,
            </Link>{' '}
              including the{' '}
              <Link className={styles.privacyLink} to='/'>
              User Agreement{' '}
            </Link>
            and{' '}
              <Link className={styles.privacyLink} to='/'>
              Privacy Policy
            </Link>
          </span>
          </label>
          {/*{errors.termsAccepted && <p className={styles.termsAccepted}>{errors.termsAccepted.message}</p>}*/}
        </aside>

        <CreateProfileNavigation backLink='' />
      </form>

      <PopUpCreateProfile
        title='Citizenship'
        popupOpen={isCitizenshipPopupOpen}
        setPopupOpen={setIsCitizenshipPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountry(value);
          setValue('citizenship', value);
        }}
        selectedObject={selectedCountry}
        itemArray={countries}
      />
      <PopUpCreateProfile
        title='Profession'
        popupOpen={isProfessionPopupOpen}
        setPopupOpen={setIsProfessionPopupOpen}
        setSelectedObject={(value) => {
          setSelectedProfession(value);
          setValue('profession', value);
        }}
        selectedObject={selectedProfession}
        itemArray={profession}
      />
      <PopUpVerifyPhone
        title='Verify phone number to proceed'
        popupOpen={isVerifyPhonePopupOpen}
        setPopupOpen={setIsVerifyPhonePopupOpen}
        setSelectedObject={(value) => {
          setSelectedPhone(value);
          setValue('phoneNumber', value);
        }}
      />
    </section>
  );
};

export default CreateProfile;
