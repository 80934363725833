import React from 'react';
import { Link } from 'react-router-dom';

import styles from './welcomeToPerfomxEmployer.module.scss';

const reasonsArray = [
  { title: 'Time-saving', desc: 'Quickly find suitable job opportunities thanks to the convenient filter' },
  {
    title: 'Direct communication with employers',
    desc: 'Excluding agencies and communicating directly with employers',
  },
  {
    title: 'Transparent conditions',
    desc: 'Enter into a job offer agreement with the employer for clarity of employment terms',
  },
  {
    title: 'Verified vacancies',
    desc: 'Only verified employers and vacancies that have passed the verification process',
  },
];

const WelcomeToPerfomxEmployer = () => {
  return (
    <section className={styles.welcome}>
      <h3 className={styles.welcomeTitle}>Welcome to PerfomX!</h3>
      <p className={styles.welcomeDesc}>Find high-paying jobs anywhere in the world and expand your network</p>
      <p className={styles.welcomeAttention}>Your data is being processed and will be reviewed within 24 hours.</p>
      <h4 className={styles.welcomeSubTitle}>Reasons to choose PerfomX for job search:</h4>
      <ul className={styles.welcomeItems}>
        {reasonsArray.map((item, index) => (
          <li className={styles.welcomeItem} key={index}>
            <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M2 6.36842L6.03333 11L13 3' stroke='#219653' strokeWidth='2.5' strokeLinecap='round' />
            </svg>
            <aside>
              <h5 className={styles.welcomeItemTitle}>{item.title}</h5>
              <p className={styles.welcomeItemDesc}>{item.desc}</p>
            </aside>
          </li>
        ))}
      </ul>
      <p className={styles.welcomeDesc}>
        You can proceed to search for vacancies or continue to fill out the form and apply for a job.
      </p>
      <nav className={styles.welcomeNav}>
        <Link className={styles.welcomeLink} to='/employer/create-profile-2'>
          Apply for a job
        </Link>
        <Link className={styles.welcomeLink} to='/employer/talents'>
          Search for jobs
        </Link>
      </nav>
    </section>
  );
};

export default WelcomeToPerfomxEmployer;
