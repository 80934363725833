import React, { useState } from 'react';
import cross from '../../../img/Ctrate-form/cross_black.svg';

import styles from './popUpVerifyPhone.module.scss';

const PopUpVerifyPhone = ({ title, popupOpen, setPopupOpen, setSelectedObject }) => {
  const [otherInput, setOtherInput] = useState('');
  const enterCode = false;

  const handleCountrySelect = (number: string) => {
    setOtherInput('');
    setSelectedObject(number);
  };

  const handleSelectButton = () => {
    if (otherInput.trim() !== '') {
      setSelectedObject(otherInput);
    }
    setPopupOpen(false);
  };

  return (
    popupOpen && (
      <article className={styles.popupPhone}>
        <div className={styles.popUpBg} />
        <div className={styles.popUpContainer}>
          <button className={styles.closeBtn} type='button' onClick={() => setPopupOpen(false)}>
            <img width={19} height={19} src={cross} alt='close cross' />
          </button>
          <hr className={styles.topLine} />
          <h2 className={styles.titlePopup}>{title}</h2>
          <p className={styles.desc}>We'll text you a code to verify your number</p>
          <div className={styles.numberBox}>
            <input
              type='number'
              className={styles.numberInput}
              placeholder='Number:'
              value={otherInput}
              onChange={(e) => setOtherInput(e.target.value)}
            />
            <button className={styles.clearNumberBtn} onClick={() => handleCountrySelect('')} type='button'>
              <img width={19} height={19} src={cross} alt='close cross' />
            </button>
          </div>
          {enterCode && (
            <aside className={styles.codeBox}>
              <input className={styles.enterCode} type='number' placeholder='Enter code' />
              <hr className={styles.lineCode} />
              <p className={styles.codeDesc}>Enter 6-digit code</p>
            </aside>
          )}
          <p className={styles.desc}>
            Messaging rates may apply. We use this for verification purposes only and will share your info with PerfomX
            users or for marketing purposes
          </p>
          <button className={styles.sendBtn} onClick={handleSelectButton} type='button'>
            Select
          </button>
        </div>
      </article>
    )
  );
};

export default PopUpVerifyPhone;
