import React from 'react';
import Footer from '../../../components/Talent/Footer';
import club360Img from '../../../img/Apply/club_360_Img.jpg';
import chat from '../../../img/Apply/chat.svg';

import styles from './apply.module.scss';

const applyEmployerArray = [
  { img: club360Img, businessType: 'Club 360', online: '18:27', countryJobPosting: 'UAE', city: 'Dubai' },
  { img: club360Img, businessType: 'Dubai Club', online: '', countryJobPosting: 'UAE', city: 'Dubai' },
  { img: club360Img, businessType: 'SV Models', online: '18:27', countryJobPosting: 'UAE', city: 'Dubai' },
];

const Apply = () => {
  return (
    <div className={styles.apply}>
      <header>
        <h1 className={styles.title}>Apply</h1>
      </header>
      <main className={styles.main}>
        {applyEmployerArray ? (
          <>
            <section className={styles.content}>
              <h2 className={styles.subTitle}>New:</h2>
              {applyEmployerArray.map((item, index) => (
                <article className={styles.wrapper} key={index}>
                  <div className={styles.employer}>
                    <img width={90} height={90} src={item.img} alt='employer img' />
                    <div className={styles.employerBox}>
                      <h3 className={styles.employerTitle}>
                        {item.businessType} <span className={item.online ? styles.online : styles.ofLine} />
                      </h3>
                      <p className={styles.employerDesc}>
                        {item.city}, {item.countryJobPosting}
                      </p>
                      <time className={styles.outline}>Today, at {item.online}</time>
                    </div>
                    <img width={46} height={46} src={chat} alt='chat' />
                  </div>
                </article>
              ))}
              <hr className={styles.line} />
            </section>
            <section className={styles.content}>
              <h2 className={styles.subTitle}>This week:</h2>
              {applyEmployerArray.map((item, index) => (
                <article className={styles.wrapper} key={index}>
                  <div className={styles.employer}>
                    <img width={90} height={90} src={item.img} alt='employer img' />
                    <div className={styles.employerBox}>
                      <h3 className={styles.employerTitle}>
                        {item.businessType} <span className={item.online ? styles.online : styles.ofLine} />
                      </h3>
                      <p className={styles.employerDesc}>
                        {item.city}, {item.countryJobPosting}
                      </p>
                      <time className={styles.outline}>Today, at {item.online}</time>
                    </div>
                    <img width={46} height={46} src={chat} alt='chat' />
                  </div>
                </article>
              ))}
              <hr className={styles.line} />
            </section>
            <section className={styles.content}>
              <h2 className={styles.subTitle}>Last Week:</h2>
              {applyEmployerArray.map((item, index) => (
                <article className={styles.wrapper} key={index}>
                  <div className={styles.employer}>
                    <img width={90} height={90} src={item.img} alt='employer img' />
                    <div className={styles.employerBox}>
                      <h3 className={styles.employerTitle}>
                        {item.businessType} <span className={item.online ? styles.online : styles.ofLine} />
                      </h3>
                      <p className={styles.employerDesc}>
                        {item.city}, {item.countryJobPosting}
                      </p>
                      <time className={styles.outline}>Today, at {item.online}</time>
                    </div>
                    <img width={46} height={46} src={chat} alt='chat' />
                  </div>
                </article>
              ))}
              <hr className={styles.line} />
            </section>
          </>
        ) : (
          <p className={styles.desc}>There are currently no applications</p>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Apply;
