import React from 'react';
import styles from './parameters.module.scss';
import { Link } from 'react-router-dom';

const Parameters = () => {
  return (
    <section className={styles.parameters}>
      <h1 className={styles.title}>Parameters</h1>
      <article className={styles.descBox}>
        <p className={styles.desc}>
          According to your set filters, there are no suggestions available. Would you like to change the parameters?
        </p>
        <div className={styles.linkBox}>
          <Link className={styles.link} to='/talent/jobs'>
            No
          </Link>
          <Link className={styles.link} to='/talent/filter'>
            Yes
          </Link>
        </div>
      </article>
    </section>
  );
};

export default Parameters;
