import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from '../../../components/Talent/Footer';
import club from '../../../img/Jobs/club.jpg';
import club2 from '../../../img/Jobs/club_2.jpg';
import club3 from '../../../img/Jobs/club_3.jpg';
import backArrow from '../../../img/Jobs/back_arrow.svg';
import menu3points from '../../../img/Jobs/menu_3_points.svg';
import favorites from '../../../img/Jobs/favorites.svg';
import checkMark from '../../../img/Jobs/check_mark.svg';
import { employerProfileObject } from '../Jobs/employerProfileArray';
import { vacancies } from '../Vacancies/mocDataVacancies';
import groupImg from '../../../img/Vacancies/group.svg';
import favoritesImg from '../../../img/Vacancies/favorites.svg';
import noFavoritesImg from '../../../img/Vacancies/no_favorites.svg';
import reviewsAvatar from '../../../img/Jobs/avatar.svg';
import ratingStarActive from '../../../img/Jobs/rating_star_active.svg';
import ratingStar from '../../../img/Jobs/rating_star.svg';
import warning from '../../../img/Jobs/warning.svg';
import closeArrow from '../../../img/Jobs/close_arrow.svg';

import styles from './aboutEmployer.module.scss';

const jobsArray = [club2, club, club3, club, club2, club3, club, club2];

const AboutEmployer = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      stars: 0,
      review: '',
    },
  });

  const [open, setOpen] = useState(false);
  const [menuPopupComplaint, setMenuPopupComplaint] = useState(false);
  const [blockedPopUp, setBlockedPopUp] = useState(false);
  const [reviewPopUp, setReviewPopUp] = useState(false);

  const blockedPopUpFunction = () => {
    if (blockedPopUp) {
      setTimeout(() => {
        setBlockedPopUp(false);
      }, 3000);
    }
    return blockedPopUp;
  };


  const settings = {
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    dotsClass: styles.dots,
  };

  const settingsEmployer = {
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 2,
    dots: false,
  };

  const rating = 5;
  const ratingEmployer = 4.2;
  const roundedRating = Math.round(ratingEmployer);

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className={styles.aboutEmployer}>
      <header className={styles.header}>
        <nav className={styles.navBox}>
          <Link to='/talent/jobs'>
            <img width={32} height={32} src={backArrow} alt='back arrow' />
          </Link>
          <button type='button' onClick={() => setMenuPopupComplaint(!menuPopupComplaint)}>
            <img width={32} height={32} src={menu3points} alt='menu 3 points' />
          </button>
        </nav>
        <Slider {...settings} className={styles.sliderBox}>
          {jobsArray.map((item, index) => (
            <img width={390} height={362} src={item} alt='club.jpg' key={index} />
          ))}
        </Slider>
      </header>
      <main className={styles.main}>
        <article className={styles.employerBox}>
          <div>
            <p className={styles.employerTitle}>
              <span className={styles.employerTitleBolt}>{employerProfileObject.businessName} /</span>
              <span className={styles.businessType}>Agency</span>
            </p>
            <p className={styles.desc}>Turkey, Istanbul</p>
            <p className={`${styles.desc} ${styles.online}`}>Online: a day ago</p>
          </div>
          <aside>
            <p className={styles.rating}>
              Rating 4.96 <img width={18} height={18} src={favorites} alt='favorites' />{' '}
            </p>
            <p className={styles.reviews}>Reviews: 168</p>
          </aside>
        </article>
        <hr className={styles.line} />
        <h2 className={styles.title}>Company details:</h2>
        <ul className={styles.box}>
          <li className={styles.desc}>Registered on the site: 25.05.2022</li>
          <li className={styles.desc}>Signed contracts: 10</li>
          <li className={styles.desc}>Arbitration: 5</li>
          <li className={styles.verified}>
            <span>Documents verified</span>
            <img width={15} height={14} src={checkMark} alt='Documents verified' />
          </li>
        </ul>
        <h2 className={styles.title}>About the company:</h2>
        <article className={`${styles.text} ${open && styles.textOpen}`}>
          <p className={`${open ? styles.textBox : styles.ellipsis} ${styles.desc}`}>
            SV Models is a dynamically growing agency dedicated to discovering and promoting talented models in the
            fashion and advertising industries. We build strong connections between models and clients, helping our
            talents to forge successful careers on a global scale. Our team consists of experts with years of experience
            who deeply understand the specifics of the industry and can offer a personalized approach to each
            individual. At SV Models, we strive to help every model unlock their potential and achieve success in their
            chosen field. We take pride in helping our models become the faces of leading brands and participate in the
            most prestigious projects.
          </p>
        </article>
        <button className={`${styles.expand}`} onClick={() => setOpen(!open)} type='button'>
          {open ? 'Hide' : 'Expand'}
        </button>
        <h2 className={styles.title}>All vacancies:</h2>
        <article className={styles.slider}>
          <Slider {...settingsEmployer} className={styles.vacanciesWrapper}>
            {vacancies.map((item, index) => (
              <Link className={styles.linkToJobs} to={`/talent/jobs/${item.id}`} key={index}>
                <figure className={styles.vacanciesContainer}>
                  {item.top && <p className={styles.top}>{item.top}</p>}
                  {item.applicants > 0 && (
                    <div className={styles.group}>
                      <img width={16} height={16} src={groupImg} alt='group' />
                      <p className={styles.groupDesc}>{item.applicants}</p>
                    </div>
                  )}
                  <img
                    className={styles.favorites}
                    width={24}
                    height={24}
                    src={item.favorites ? favoritesImg : noFavoritesImg}
                    alt='favorites'
                  />
                  <img
                    className={styles.vacanciesImg}
                    width={171}
                    height={171}
                    src={item.photoPlaceWork[0].url}
                    alt='house'
                  />
                  <aside className={styles.vacanciesDesc}>
                    <p>{item.workplace}</p>
                    <p>$ {item.salary}</p>
                  </aside>
                  <figcaption className={styles.vacanciesTitle}>
                    {item.cities}, {item.countryJobPosting}
                  </figcaption>
                </figure>
              </Link>
            ))}
          </Slider>
        </article>
        <div className={styles.reviewsBox}>
          <h2 className={styles.title}>Reviews:</h2>
          <button type='button' className={styles.expand} onClick={() => setReviewPopUp(!reviewPopUp)}>
            Leave a review
          </button>
        </div>
        <article className={styles.employerReviews}>
          <div className={styles.employerWrapper}>
            <div className={styles.employerInfo}>
              <img width={62} height={62} src={reviewsAvatar} alt='reviews Avatar' />
              <div className={styles.employerContainer}>
                <div className={styles.employerNameBox}>
                  <p className={styles.employerName}>Sergey S.</p>
                  {/*<img width={16} height={16} src={check} alt='check' />*/}
                </div>
                <div className={styles.employerNameBox}>
                  <p className={styles.employerDesc}>Moscow, </p>
                  <p className={styles.employerDesc}>Russia</p>
                </div>
              </div>
            </div>
            <div className={styles.employerContainer}>
              <p className={styles.ratingDesc}>Performer's rating:</p>
              <div className={styles.rating}>
                {Array(roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`active-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStarActive}
                      alt='rating active star'
                    />
                  ))}
                {Array(rating - roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`inactive-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStar}
                      alt='rating inactive star'
                    />
                  ))}
                <p className={styles.ratingDesc}>{ratingEmployer}</p>
              </div>
            </div>
          </div>
          <aside className={styles.reviewsDesc}>
            Hello.  My partner with sufficient work experience, eloquent speech, citizenship of the Russian Federation
            (Republic of Belarus), and a passport is ready to complete your task. All responsibility will lie with me.
            Please write or, better yet, call to discuss the details of the task, and then entrust it to me and my
            colleague. Thank you.
          </aside>
        </article>
        <article className={styles.employerReviews}>
          <div className={styles.employerWrapper}>
            <div className={styles.employerInfo}>
              <img width={62} height={62} src={reviewsAvatar} alt='reviews Avatar' />
              <div className={styles.employerContainer}>
                <div className={styles.employerNameBox}>
                  <p className={styles.employerName}>Sergey S.</p>
                  {/*<img width={16} height={16} src={check} alt='check' />*/}
                </div>
                <div className={styles.employerNameBox}>
                  <p className={styles.employerDesc}>Moscow, </p>
                  <p className={styles.employerDesc}>Russia</p>
                </div>
              </div>
            </div>
            <div className={styles.employerContainer}>
              <p className={styles.ratingDesc}>Performer's rating:</p>
              <div className={styles.rating}>
                {Array(roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`active-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStarActive}
                      alt='rating active star'
                    />
                  ))}
                {Array(rating - roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`inactive-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStar}
                      alt='rating inactive star'
                    />
                  ))}
                <p className={styles.ratingDesc}>{ratingEmployer}</p>
              </div>
            </div>
          </div>
          <aside className={styles.reviewsDesc}>
            Hello.  My partner with sufficient work experience, eloquent speech, citizenship of the Russian Federation
            (Republic of Belarus), and a passport is ready to complete your task. All responsibility will lie with me.
            Please write or, better yet, call to discuss the details of the task, and then entrust it to me and my
            colleague. Thank you.
          </aside>
        </article>
        <article className={styles.employerReviews}>
          <div className={styles.employerWrapper}>
            <div className={styles.employerInfo}>
              <img width={62} height={62} src={reviewsAvatar} alt='reviews Avatar' />
              <div className={styles.employerContainer}>
                <div className={styles.employerNameBox}>
                  <p className={styles.employerName}>Sergey S.</p>
                  {/*<img width={16} height={16} src={check} alt='check' />*/}
                </div>
                <div className={styles.employerNameBox}>
                  <p className={styles.employerDesc}>Moscow, </p>
                  <p className={styles.employerDesc}>Russia</p>
                </div>
              </div>
            </div>
            <div className={styles.employerContainer}>
              <p className={styles.ratingDesc}>Performer's rating:</p>
              <div className={styles.rating}>
                {Array(roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`active-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStarActive}
                      alt='rating active star'
                    />
                  ))}
                {Array(rating - roundedRating)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      key={`inactive-star-${index}`}
                      width={14}
                      height={14}
                      src={ratingStar}
                      alt='rating inactive star'
                    />
                  ))}
                <p className={styles.ratingDesc}>{ratingEmployer}</p>
              </div>
            </div>
          </div>
          <aside className={styles.reviewsDesc}>
            Hello.  My partner with sufficient work experience, eloquent speech, citizenship of the Russian Federation
            (Republic of Belarus), and a passport is ready to complete your task. All responsibility will lie with me.
            Please write or, better yet, call to discuss the details of the task, and then entrust it to me and my
            colleague. Thank you.
          </aside>
        </article>
      </main>
      {!reviewPopUp && <Footer />}
      {menuPopupComplaint && (
        <article className={styles.popupComplaint}>
          <button className={styles.popupComplaintDesc} type='button'>
            Complain
          </button>
          <button
            className={styles.popupComplaintDesc}
            type='button'
            onClick={() => {
              setBlockedPopUp(true);
              setMenuPopupComplaint(!menuPopupComplaint);
            }}>
            Block
          </button>
        </article>
      )}
      {blockedPopUpFunction() && (
        <article className={styles.warning}>
          <img width={86} height={86} src={warning} alt='warning - user is blocked' />
          <p className={styles.warningDesc}>User is blocked</p>
        </article>
      )}
      {reviewPopUp && (
        <article className={styles.reviewPopUp}>
          <div className={styles.headerBox}>
            <button type='button' onClick={() => setReviewPopUp(!reviewPopUp)}>
              <img width={22} height={22} src={closeArrow} alt='close arrow' />
            </button>
            <h4 className={styles.reviewPopUpTitle}>
              SV Models / <span>Agensy</span>
            </h4>
          </div>
          <h4 className={styles.reviewPopUpSubTitle}>Rate</h4>
          <form className={styles.rate} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.stars}>
              {[...Array(5)].map((_, index) => (
                <label className={styles.label} key={index}>
                  <input
                    type='radio'
                    value={index + 1}
                    {...register('stars', { required: 'Please give a stars' })}
                    hidden
                  />
                  <img
                    width={33}
                    height={33}
                    src={index < watch('stars') ? ratingStarActive : ratingStar}
                    alt={index < watch('stars') ? 'active star' : 'inactive star'}
                  />
                </label>
              ))}
            </div>
            {errors.stars && <p className={styles.errorMessage}>{errors.stars.message}</p>}
            <h4 className={styles.reviewPopUpSubTitle}>Review:</h4>
            <p className={styles.reviewPopUpDesc}>
              This review will be published on the employer's profile and will help other applicants make the right
              decision.
            </p>
            <textarea className={styles.textarea} {...register('review', { required: 'Please write a review' })} />
            {errors.review && <p className={styles.errorMessage}>{errors.review.message}</p>}
            <button className={styles.reviewPopUpSubmit} type='submit'>
              Send review
            </button>
          </form>
        </article>
      )}
    </div>
  );
};

export default AboutEmployer;
