import React from 'react';
import { Link } from 'react-router-dom';
import headerImage from '../../img/Home-page/header.png';
import cIcon from '../../img/Home-page/c_icon.png';
import cIconBlack from '../../img/Home-page/c_icon_black.svg';

import styles from './footerHomePage.module.scss';
import LinkButton from "../Talent/LinkButton";

const itemMenuArray = [
  { title: 'About Us', link: '/about-us' },
  { title: 'FAQ', link: '/faq' },
  { title: 'Career', link: '/career' },
  { title: 'Contacts', link: '/contact' },
  { title: 'Privacy Policy', link: '/privacy-policy' },
];

const FooterHomePage = ({ footer }) => {
  return (
    <footer className={`${footer === 'homePage' ? styles.footer : ''}`}>
      {footer === 'homePage' && (
        <>
          <h4 className={styles.footerTitle}>Become a part of</h4>
          <img width={140} height={26} src={headerImage} alt='logo' />
          <p className={styles.footerDesc}>Job in entertainment</p>
          <LinkButton title='Join us' link='/talent/create-profile-1' />
        </>
      )}
      <article className={`${footer === 'homePage' ? styles.footerMenu : styles.otherPage}`}>
        <nav className={styles.footerNav}>
          {itemMenuArray.map((item, index) => (
            <Link
              className={`${footer === 'homePage' ? styles.linkItem : styles.linkItemBlack}`}
              to={item.link}
              key={index}>
              {item.title}
            </Link>
          ))}
        </nav>
        <aside className={styles.footerRights}>
          <img width='10' height='10' src={footer === 'homePage' ? cIcon : cIconBlack} alt='rights' />
          <p className={`${footer === 'homePage' ? styles.footerRightsDesc : styles.footerRightsDescBlack}`}>
            2023 Joliex
          </p>
        </aside>
      </article>
    </footer>
  );
};

export default FooterHomePage;
