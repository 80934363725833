import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import backArrow from '../../../img/Chat/back_arrow.svg';
import menu from '../../../img/Chat/menu.svg';
import searchIcon from '../../../img/Chat/search_icon.svg';
import avatar1 from '../../../img/Chat/avatar_1.jpg';
import checkmarks from '../../../img/Chat/checkmarks.svg';
import Footer from '../../../components/Talent/Footer';

import styles from './chat.module.scss';

const chatArray = [
  {
    iconEmployer: avatar1,
    businessName: 'PerfomX',
    businessType: 'Admin',
    messagesEmployer: 'Good afternoon, your appli afternoon, your appli.. afternoon, your appli....',
    unreadMessages: 12,
    lastMessageTime: '12:25',
  },
  {
    iconEmployer: avatar1,
    businessName: 'Dancer',
    businessType: 'SV Models',
    messagesEmployer: 'Agree',
    unreadMessages: 0,
    lastMessageTime: '19:13',
  },
];
const itemMenuArray = ['All', 'Unread', 'Favorites', 'Blacklist', 'Archive', 'Delete chat'];

const Chat = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [activeBtn, setActiveBtn] = useState('Unread');

  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.chat}>
      <header className={styles.header}>
        <Link className={styles.backArrow} to='/talent/jobs'>
          <img src={backArrow} alt='back arrow' />
        </Link>
        <label className={styles.label}>
          <img src={searchIcon} alt='search icon' />
          <input className={styles.inputSearch} type='text' placeholder='Search..' />
        </label>
        <button className={styles.menuBtn} type='button' onClick={() => setOpenMenu(!openMenu)}>
          <img src={menu} alt='menu' />
        </button>
      </header>
      <main className={styles.main}>
        {chatArray.map((item, index) => (
          <article className={styles.chatWrapper} key={index}>
            <img src={item.iconEmployer} alt='avatar_1.jpg' />
            <div>
              <h4 className={styles.chatTitle}>
                {item.businessName} {item.businessType}
              </h4>
              <p className={styles.chatDesc}>{item.messagesEmployer}</p>
            </div>
            <div className={styles.chatContainer}>
              <div className={styles.chatBox}>
                {item.unreadMessages !== 0 && <img src={checkmarks} alt='checkmarks' />}
                <time className={styles.time}>{item.lastMessageTime} PM</time>
              </div>
              <p className={styles.numberMessages}>{item.unreadMessages}</p>
            </div>
          </article>
        ))}
      </main>
      <Footer />
      {openMenu && (
        <article ref={menuRef} className={styles.menu}>
          {itemMenuArray.map((item, index) => (
            <button
              className={`${styles.menuItem} ${activeBtn === item && styles.menuItemActive}`}
              type='button'
              key={index}
              onClick={() => setActiveBtn(item)}>
              {item}
            </button>
          ))}
        </article>
      )}
    </div>
  );
};

export default Chat;
