import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CreateProfile from './pages/Talent/CreateProfile';
import CreateProfileVerification from './pages/Talent/CreateProfileVerification';
import CreateProfilePassport from './pages/Talent/CreateProfilePassport';
import WelcomeToPerfomx from './pages/Talent/WelcomeToPerfomx';
import CreateProfileJobApplication from './pages/Talent/CreateProfileJobApplication';
import CreateProfileMorePhotos from './pages/Talent/CreateProfileMorePhotos';
import PhotoPlacementRules from './pages/Talent/PhotoPlacementRules';
import CongratulationsJobApplication from './pages/Talent/CongratulationsJobApplication';
import Vacancies from './pages/Talent/Vacancies';
import Filter from './pages/Talent/Filter';
import Parameters from './pages/Talent/Parameters';
import AboutUs from './pages/AboutUs';
import HotBarMenu from './pages/HotBarMenu';
import Faq from './pages/Faq';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Careers from './pages/Talent/Career/Career';
import ContactUs from './pages/ContactUs';
import Feedback from './pages/Feedback';
import Jobs from './pages/Talent/Jobs';
import JobAlert from './pages/Talent/JobAlert';
import { RootStoreProvider } from './RootStore';
import AboutEmployer from './pages/Talent/AboutEmployer';
import Apply from './pages/Talent/Apply/Apply';
import Chat from './pages/Talent/Chat';
import Profile from './pages/Talent/Profile';
import ViewProfile from './pages/Talent/ViewProfile';
import BoostProfile from './pages/Talent/BoostProfile/BoostProfile';
import BecomeTopTalent from './pages/Talent/BecomeTopTalent';
import ReceiveNotifications from './pages/Talent/ReceiveNotifications';
import GetBonuses from './pages/Talent/GetBonuses';
// ----------------------
import CreateProfileEmployer from './pages/Employer/CreateProfileEmployer/CreateProfileEmployer';
import WelcomeToPerfomxEmployer from './pages/Employer/WelcomeToPerfomxEmployer';
import FormPostJob from './pages/Employer/FormPostJob';
import MoreInformation from "./pages/Employer/MoreInformation";
import PhotoPlacementRulesEmployer from "./pages/Employer/PhotoPlacementRulesEmployer";
import Verification from "./pages/Employer/Verification";
import Talents from "./pages/Employer/Talents";

function App() {
  return (
    <RootStoreProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/talent/create-profile-1' element={<CreateProfile />} />
          <Route path='/talent/create-profile-2' element={<CreateProfileJobApplication />} />
          <Route path='/talent/create-profile-3' element={<CreateProfileMorePhotos />} />
          <Route path='/talent/create-profile-4' element={<CreateProfileVerification />} />
          <Route path='/talent/create-profile-5' element={<CreateProfilePassport />} />
          <Route path='/talent/welcome-to-perfomx' element={<WelcomeToPerfomx />} />
          <Route path='/talent/create-profile-5/photo-placement-rules' element={<PhotoPlacementRules />} />
          <Route path='/talent/congratulations-job-application' element={<CongratulationsJobApplication />} />
          <Route path='/talent/jobs' element={<Vacancies />} />
          <Route path='/talent/jobs/:id' element={<Jobs />} />
          <Route path='/talent/filter' element={<Filter />} />
          <Route path='/talent/parameters' element={<Parameters />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/hot-bar' element={<HotBarMenu />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/career' element={<Careers />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/feedback' element={<Feedback />} />
          <Route path='/talent/job-alert' element={<JobAlert />} />
          <Route path='/employer/:employerName' element={<AboutEmployer />} />
          <Route path='/talent/apply' element={<Apply />} />
          <Route path='/talent/chat' element={<Chat />} />
          <Route path='/talent/profile' element={<Profile />} />
          <Route path='/talent/view-profile' element={<ViewProfile />} />
          <Route path='/talent/view-profile' element={<ViewProfile />} />
          <Route path='/talent/boost-profile' element={<BoostProfile />} />
          <Route path='/talent/become-a-top-talent' element={<BecomeTopTalent />} />
          <Route path='/talent/receive-notifications' element={<ReceiveNotifications />} />
          <Route path='/talent/get-bonuses' element={<GetBonuses />} />
          {/*-------------------------------*/}
          <Route path='/employer/create-profile-1' element={<CreateProfileEmployer />} />
          <Route path='/employer/welcome-to-perfomx' element={<WelcomeToPerfomxEmployer />} />
          <Route path='/employer/create-profile-2' element={<FormPostJob />} />
          <Route path='/employer/create-profile-3' element={<MoreInformation />} />
          <Route path='/employer/create-profile-3/photo-placement-rules' element={<PhotoPlacementRulesEmployer />} />
          <Route path='/employer/create-profile-4' element={<Verification />} />
          <Route path='/employer/talents' element={<Talents />} />
        </Routes>
      </BrowserRouter>
    </RootStoreProvider>
  );
}

export default App;
