import { flow, types } from 'mobx-state-tree';
// import { api } from '../../api';
// import axios from 'axios';

const { model, frozen } = types;

export const HomePageStore = model('HomePageStore', {
  click: types.boolean,
}).actions((self) => ({
  setClick(value: boolean) {
    self.click = value;
  },

  /*afterCreate() {
    Promise.all([self.getContent(), self.getPosts(), self.getCategories()]).then(() => {
      self.getHomePage();
    });
  },*/

}));

export default HomePageStore;
