import React from 'react';
import { contactUsArray } from './contactUsArray';
import LinkButton from '../../components/Talent/LinkButton';

import styles from './contactUs.module.scss';

const ContactUs = () => {
  return (
    <div className={styles.contactUs}>
      <header>
        <h1 className={styles.title}>Contact us</h1>
        <address>
          <p className={styles.desc}>
            <br /> Dornoom LP
            <br /> SL21291
            <br /> Suite 3 Castle House, 1 Baker Street, Stirling,
            <br /> United Kingdom
            <br /> FK8 1AL
          </p>
          <p className={styles.descBox}>
            <span>Email</span>
            <a href='mailto:info@denim-app.com'>info@denim-app.com</a>
          </p>
        </address>
        <ul className={styles.item}>
          {contactUsArray.map((item, index) => (
            <li key={index}>
              <h3 className={styles.itemTitle}>{item.title}</h3>
              <p className={styles.itemDesc}>
                {item.desc} <span className={styles.highlight}>{item.link}</span>
              </p>
            </li>
          ))}
        </ul>
      </header>
      <LinkButton title='Contact us' link='/feedback' />
    </div>
  );
};

export default ContactUs;
