import talent1 from '../../../img/Talents/talent_1.jpg';
import talent2 from '../../../img/Talents/talent_2.jpg';
import talent3 from '../../../img/Talents/talent_3.jpg';
import talent4 from '../../../img/Talents/talent_4.jpg';
import talent5 from '../../../img/Talents/talent_5.jpg';
import talent6 from '../../../img/Talents/talent_6.jpg';
import talent7 from '../../../img/Talents/talent_7.jpg';
import talent8 from '../../../img/Talents/talent_8.jpg';
import talent9 from '../../../img/Talents/talent_9.jpg';

export const vacancies = [
  {
    id: '1',
    top: 'Top',
    applicants: 1,
    favorites: true,
    workplace: 'Club',
    salary: '1000',
    cities: 'Moscow',
    countryJobPosting: 'Russia',
    photoPlaceWork: [
      { id: '123', url: talent1 },
      { id: '231', url: talent2 },
      { id: '321', url: talent3 },
    ],
  },
  {
    id: '2',
    top: '',
    applicants: 0,
    favorites: false,
    workplace: 'Dancer',
    salary: '1500',
    cities: 'Dubai',
    countryJobPosting: 'UAE',
    photoPlaceWork: [
      { id: '123', url: talent3 },
      { id: '231', url: talent1 },
      { id: '321', url: talent2 },
    ],
  },
  {
    id: '3',
    top: '',
    applicants: 147,
    favorites: false,
    workplace: 'Escort',
    salary: '3000',
    cities: 'Moscow',
    countryJobPosting: 'Russia',
    photoPlaceWork: [
      { id: '123', url: talent2 },
      { id: '231', url: talent3 },
      { id: '321', url: talent1 },
    ],
  },
  {
    id: '4',
    top: 'Top',
    applicants: 5,
    favorites: true,
    workplace: 'Escort',
    salary: '700',
    cities: 'Dubai',
    countryJobPosting: 'UAE',
    photoPlaceWork: [
      { id: '123', url: talent4 },
      { id: '231', url: talent5 },
      { id: '321', url: talent6 },
    ],
  },
  {
    id: '5',
    top: 'Top',
    applicants: 0,
    favorites: true,
    workplace: 'Escort',
    salary: '1000',
    cities: 'Moscow',
    countryJobPosting: 'Russia',
    photoPlaceWork: [
      { id: '123', url: talent5 },
      { id: '231', url: talent6 },
      { id: '321', url: talent7 },
    ],
  },
  {
    id: '6',
    top: 'Top',
    applicants: 7,
    favorites: true,
    workplace: 'Escort',
    salary: '1000',
    cities: 'Dubai',
    countryJobPosting: 'UAE',
    photoPlaceWork: [
      { id: '123', url: talent6 },
      { id: '231', url: talent8 },
      { id: '321', url: talent9 },
    ],
  },
  {
    id: '7',
    top: 'Top',
    applicants: 122,
    favorites: true,
    workplace: 'Escort',
    salary: '500',
    cities: 'Moscow',
    countryJobPosting: 'Russia',
    photoPlaceWork: [
      { id: '123', url: talent7 },
      { id: '231', url: talent1 },
      { id: '321', url: talent2 },
    ],
  },
  {
    id: '8',
    top: 'Top',
    applicants: 18,
    favorites: true,
    workplace: 'Escort',
    salary: '2500',
    cities: 'Dubai',
    countryJobPosting: 'UAE',
    photoPlaceWork: [
      { id: '123', url: talent8 },
      { id: '231', url: talent3 },
      { id: '321', url: talent5 },
    ],
  },
  {
    id: '9',
    top: 'Top',
    applicants: 7,
    favorites: true,
    workplace: 'Escort',
    salary: '1000',
    cities: 'Dubai',
    countryJobPosting: 'UAE',
    photoPlaceWork: [
      { id: '123', url: talent9 },
      { id: '231', url: talent7 },
      { id: '321', url: talent2 },
    ],
  },
];
