import React from 'react';
import FooterHomePage from '../../components/FooterHomePage';

import styles from './fac.module.scss';

const faqArray = [
  { title: 'Getting Started with PerformX' },
  { title: 'Where can I use PerformX?' },
  { title: 'What contact information is required during registration?' },
  { title: 'Do I need to pay for using PerformX?' },
  { title: 'Profile and account settings' },
  { title: 'How to avoid becoming a victim of scammers?' },
];

const Faq = () => {
  return (
    <div className={styles.faq}>
      <header className={styles.header}>
        <h1 className={styles.title}>FAQ</h1>
      </header>
      <main className={styles.main}>
        <h2 className={styles.title}>PerfomX</h2>
        <ul className={styles.question}>
          {faqArray.map((item, index) => (
            <li key={index} className={styles.questionTitle}>
              {item.title}
            </li>
          ))}
        </ul>
      </main>

      <FooterHomePage footer='' />
    </div>
  );
};

export default Faq;
