import React from 'react';
import { useForm, FieldError } from 'react-hook-form';
import arrow from '../../../img/Ctrate-form/arrow-down.svg';
import arrowBack from '../../../img/Filter/back_arrow.svg';
import PopUpCreateProfile from '../../../components/Talent/PopUpCreateProfile';
import { Link, useNavigate } from 'react-router-dom';

import styles from './filter.module.scss';

const countries = ['Malta', 'Bulgaria', 'Monaco', 'Albania', 'Germany', 'Macedonia', 'Mexico', 'Mexico'];
const cities = ['London', 'Paris', 'Berlin', 'Rome', 'Madrid', 'Amsterdam', 'Vienna', 'Lisbon', 'Prague', 'Barcelona'];

const workplace = ['Company', 'Club', 'KTV', 'Hotel', 'SPA', 'Party'];
const vacancy = [
  'Go-go Dancer',
  'Dance Group',
  'Choreographer',
  'Strip Dancer',
  'Photo Model',
  'KTV Model',
  'Image Model',
  'Crypto Model',
  'Hostess',
];
const expectedSalaryArray = [
  'From $1000 to $2000',
  'From $2000 to $3000',
  'From $3000 to $4000',
  'From $4000 to $5000',
  'From $5000 to $6000',
  'From $6000 to $8000',
];
const appearanceArray = ['Asian', 'European', 'Eastern', 'African', 'Indian'];
const languageSkillsArray = ['Chinese', 'French', 'English'];
const contractDurationArray = ['From month', 'From 2 to 3 months', 'Up to 6 months', 'Up to 1 year'];

interface FormValues {
  age: number;
  height: number;
  weight: number;
  gender: string;
  country: string;
  cities: string;
  workplace: string;
  vacancy: string;
  expectedSalary: string;
  appearance: string;
  languageSkills: string;
  contractDuration: string;
}

const Filter = () => {
  const [genderActive, setGenderActive] = React.useState('');

  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [isCountriesPopupOpen, setIsCountriesPopupOpen] = React.useState(false);

  const [selectedCities, setSelectedCities] = React.useState('');
  const [isCitiesPopupOpen, setIsCitiesPopupOpen] = React.useState(false);

  const [selectedWorkplace, setSelectedWorkplace] = React.useState('');
  const [isWorkplacePopupOpen, setIsWorkplacePopupOpen] = React.useState(false);

  const [selectedVacancies, setSelectedVacancies] = React.useState('');
  const [isVacanciesPopupOpen, setIsVacanciesPopupOpen] = React.useState(false);

  const [selectedExpectedSalary, setSelectedExpectedSalary] = React.useState('');
  const [isExpectedSalaryPopupOpen, setIsExpectedSalaryPopupOpen] = React.useState(false);

  const [selectedAppearance, setSelectedAppearance] = React.useState('');
  const [isAppearancePopupOpen, setIsAppearancePopupOpen] = React.useState(false);

  const [selectedLanguageSkills, setSelectedLanguageSkills] = React.useState('');
  const [isLanguageSkillsPopupOpen, setIsLanguageSkillsPopupOpen] = React.useState(false);

  const [selectedContractDuration, setSelectedContractDuration] = React.useState('');
  const [isContractDurationPopupOpen, setIsContractDurationPopupOpen] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setValue,
    watch,
    reset
  } = useForm<FormValues>({
    defaultValues: {
      gender: '',
      country: '',
      cities: '',
      workplace: '',
      vacancy: '',
      expectedSalary: '',
      appearance: '',
      languageSkills: '',
      contractDuration: '',
    },
  });

  const navigate = useNavigate();
  // const genderValue = watch('gender');

  const onSubmit = (data: FormValues) => {
    // navigate('/talent/jobs');
    navigate('/talent/parameters');
    console.log('Form data:', data);
  };

  const handleGenderClick = (gender: string) => {
    setGenderActive(gender);
    setValue('gender', gender);
  };

  const handleReset = () => {
    reset();
    setGenderActive('');
    setSelectedCountry('');
    setSelectedCities('');
    setSelectedWorkplace('');
    setSelectedVacancies('');
    setSelectedExpectedSalary('');
    setSelectedAppearance('');
    setSelectedLanguageSkills('');
    setSelectedContractDuration('');
  };

  return (
    <section className={styles.createProfileJobApplication}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <article className={styles.titleBox}>
          <Link to='/talent/jobs'>
            <img width={22} height={22} src={arrowBack} alt='arrow back' />
          </Link>
          <h1 className={styles.title}>Filter</h1>
          <button className={styles.reset} type='button' onClick={handleReset}>
            Reset
          </button>
        </article>
        <article className={styles.labelContainer}>
          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder='Country'
              value={selectedCountry}
              onClick={() => setIsCountriesPopupOpen(true)}
              readOnly
              {...register('country', { required: 'Country is required' })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.country && typeof errors.country.message === 'string' && (
            <p className={styles.errorMessage}>{errors.country.message}</p>
          )}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder='Cities'
              value={selectedCities}
              onClick={() => setIsCitiesPopupOpen(true)}
              readOnly
              {...register('cities', { required: 'Language skills is required' })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.cities && typeof errors.cities.message === 'string' && (
            <p className={styles.errorMessage}>{errors.cities.message}</p>
          )}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder='Workplace'
              value={selectedWorkplace}
              onClick={() => setIsWorkplacePopupOpen(true)}
              readOnly
              {...register('workplace', { required: 'Contract country is required' })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.workplace && <p className={styles.errorMessage}>{errors.workplace.message}</p>}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder='Vacancy'
              value={selectedVacancies}
              onClick={() => setIsVacanciesPopupOpen(true)}
              readOnly
              {...register('vacancy', { required: 'vacancy is required' })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.vacancy && <p className={styles.errorMessage}>{errors.vacancy.message}</p>}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder='Salary'
              value={selectedExpectedSalary}
              onClick={() => setIsExpectedSalaryPopupOpen(true)}
              readOnly
              {...register('expectedSalary', { required: 'Salary is required' })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.expectedSalary && <p className={styles.errorMessage}>{errors.expectedSalary.message}</p>}
        </article>
        <article>
          <h3 className={styles.subTitle}>Gender:</h3>
          <aside className={styles.genderBtnBox}>
            <label className={`${styles.genderBtn} ${genderActive === 'male' && styles.genderSelection}`}>
              <input
                className={styles.createProfileInput}
                placeholder='Male'
                type='radio'
                value='male'
                {...register('gender', { required: 'Gender is required' })}
                onClick={() => handleGenderClick('male')}
                checked={genderActive === 'male'}
                hidden
              />
              Male
            </label>

            <label className={`${styles.genderBtn} ${genderActive === 'female' && styles.genderSelection}`}>
              <input
                className={styles.createProfileInput}
                placeholder='Female'
                type='radio'
                value='female'
                {...register('gender', { required: 'Gender is required' })}
                onClick={() => handleGenderClick('female')}
                checked={genderActive === 'female'}
                hidden
              />
              Female
            </label>
          </aside>
          {errors.gender && <p className={styles.errorMessage}>{errors.gender.message}</p>}
        </article>
        <article className={styles.labelContainer}>
          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder='Appearance'
              value={selectedAppearance}
              onClick={() => setIsAppearancePopupOpen(true)}
              readOnly
              {...register('appearance', { required: 'Appearance is required' })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.appearance && <p className={styles.errorMessage}>{errors.appearance.message}</p>}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder='Language skills'
              value={selectedLanguageSkills}
              onClick={() => setIsLanguageSkillsPopupOpen(true)}
              readOnly
              {...register('languageSkills', { required: 'Language skills is required' })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.languageSkills && <p className={styles.errorMessage}>{errors.languageSkills.message}</p>}

          <label className={styles.labelBox}>
            <input
              className={styles.createProfileInput}
              placeholder='Contract duration'
              value={selectedContractDuration}
              onClick={() => setIsContractDurationPopupOpen(true)}
              readOnly
              {...register('contractDuration', { required: 'Contract duration is required' })}
            />
            <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
          </label>
          {errors.contractDuration && <p className={styles.errorMessage}>{errors.contractDuration.message}</p>}
        </article>
        <p className={styles.desc}>
          Refine search parameters to display only those vacancies that match the data specified in your profile.
        </p>
        <button className={styles.submitBtn} type='submit'>
          Apply
        </button>
      </form>
      <PopUpCreateProfile
        title='Countries'
        popupOpen={isCountriesPopupOpen}
        setPopupOpen={setIsCountriesPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountry(value);
          setValue('country', value);
        }}
        selectedObject={selectedCountry}
        itemArray={countries}
      />
      <PopUpCreateProfile
        title='Cities'
        popupOpen={isCitiesPopupOpen}
        setPopupOpen={setIsCitiesPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCities(value);
          setValue('cities', value);
        }}
        selectedObject={selectedCities}
        itemArray={cities}
      />
      <PopUpCreateProfile
        title='Workplace'
        popupOpen={isWorkplacePopupOpen}
        setPopupOpen={setIsWorkplacePopupOpen}
        setSelectedObject={(value) => {
          setSelectedWorkplace(value);
          setValue('workplace', value);
        }}
        selectedObject={selectedWorkplace}
        itemArray={workplace}
      />
      <PopUpCreateProfile
        title='Vacancies'
        popupOpen={isVacanciesPopupOpen}
        setPopupOpen={setIsVacanciesPopupOpen}
        setSelectedObject={(value) => {
          setSelectedVacancies(value);
          setValue('vacancy', value);
        }}
        selectedObject={selectedVacancies}
        itemArray={vacancy}
      />
      <PopUpCreateProfile
        title='Expected Salary'
        popupOpen={isExpectedSalaryPopupOpen}
        setPopupOpen={setIsExpectedSalaryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedExpectedSalary(value);
          setValue('expectedSalary', value);
        }}
        selectedObject={selectedExpectedSalary}
        itemArray={expectedSalaryArray}
      />
      <PopUpCreateProfile
        title='Appearance'
        popupOpen={isAppearancePopupOpen}
        setPopupOpen={setIsAppearancePopupOpen}
        setSelectedObject={(value) => {
          setSelectedAppearance(value);
          setValue('appearance', value);
        }}
        selectedObject={selectedAppearance}
        itemArray={appearanceArray}
      />
      <PopUpCreateProfile
        title='Language skills'
        popupOpen={isLanguageSkillsPopupOpen}
        setPopupOpen={setIsLanguageSkillsPopupOpen}
        setSelectedObject={(value) => {
          setSelectedLanguageSkills(value);
          setValue('languageSkills', value);
        }}
        selectedObject={selectedLanguageSkills}
        itemArray={languageSkillsArray}
      />
      <PopUpCreateProfile
        title='Contract duration'
        popupOpen={isContractDurationPopupOpen}
        setPopupOpen={setIsContractDurationPopupOpen}
        setSelectedObject={(value) => {
          setSelectedContractDuration(value);
          setValue('contractDuration', value);
        }}
        selectedObject={selectedContractDuration}
        itemArray={contractDurationArray}
      />
    </section>
  );
};

export default Filter;
