import React from 'react';
import hotBarLogo from '../../../img/Home-page/hot_bar_logo.svg';
import FooterHomePage from '../../../components/FooterHomePage';
import LinkButton from '../../../components/Talent/LinkButton/LinkButton';
import { vacanciesArray } from './careerArray';

import styles from './career.module.scss';

const Careers = () => {
  return (
    <div className={styles.careers}>
      <header className={styles.header}>
        <h1 className={styles.title}>Careers</h1>
      </header>
      <main className={styles.main}>
        <h2 className={styles.subTitle}>Join the fastest growing social media network for careers in entertainment</h2>
        <p className={styles.desc}>
          In our company, you can become part of a dynamic online project with brilliant career prospects.
        </p>
        <img className={styles.logo} width={211} height={57} src={hotBarLogo} alt='logo' />
        <LinkButton title='Open vacancies' link='/' />
        <ul className={styles.item}>
          {vacanciesArray.map((item, index) => (
            <li key={index}>
              <h4 className={styles.itemTitle}>{item.title}</h4>
              <p className={styles.itemDesc}>{item.desc}</p>
            </li>
          ))}
        </ul>
      </main>
      <FooterHomePage footer='' />
    </div>
  );
};

export default Careers;
