import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FieldError } from 'react-hook-form';
import PopUpCreateProfile from '../../../components/Talent/PopUpCreateProfile';
import CreateProfileNavigation from '../../../components/Talent/CreateProfileNavigation/CreateProfileNavigation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ProgressBar from '../../../components/Talent/ProgressBar';
import passportImg from '../../../img/Ctrate-form/job_aplication.svg';
import arrow from '../../../img/Ctrate-form/arrow-down.svg';
import calendarImg from '../../../img/Ctrate-form/calendar.svg';

import styles from './createProfileJobApplication.module.scss';

const countries = ['Asian', 'European', 'Eastern', 'African', 'Indian'];
const languages = ['Chinese', 'French', 'English'];
const countriesForWorkArray = ['Ukraine', 'Russia', 'Belarus', 'Moldova', 'Kazakhstan', 'Georgia', 'Uzbekistan'];
const contractPeriodArray = ['1 Month', 'From 2 to 3 months', 'Up to 6 months', 'Up to 1 year'];
const expectedSalaryArray = [
  'From $1000 to $2000',
  'From $2000 to $3000',
  'From $3000 to $4000',
  'From $4000 to $5000',
  'From $5000 to $6000',
  'From $6000 to $8000',
];

interface FormValues {
  age: number;
  height: number;
  weight: number;
  gender: string;
  appearance: string;
  languageSkills: string;
  workExperience: string;
  aboutYourself: string;
  location: string;
  calendar: string;
  contractCountry: string;
  contractPeriod: string;
  expectedSalary: string;
}

const CreateProfileJobApplication = () => {
  const [genderActive, setGenderActive] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [isAppearancePopupOpen, setIsAppearancePopupOpen] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState('');
  const [isLanguagePopupOpen, setIsLanguagePopupOpen] = React.useState(false);

  const [isCountryPopupOpen, setIsCountryPopupOpen] = React.useState(false);
  const [selectedCountryForWork, setSelectedCountryForWork] = React.useState('');

  const [isContractPeriodPopupOpen, setIsContractPeriodPopupOpen] = React.useState(false);
  const [selectedContractPeriod, setSelectedContractPeriod] = React.useState('');

  const [isExpectedSalaryPopupOpen, setIsExpectedSalaryPopupOpen] = React.useState(false);
  const [selectedExpectedSalary, setSelectedExpectedSalary] = React.useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: {
      age: 25,
      height: 165,
      weight: 55,
      gender: '',
      appearance: '',
      languageSkills: '',
      workExperience: '',
      aboutYourself: '',
      location: '',
      calendar: '',
      contractCountry: '',
      contractPeriod: '',
      expectedSalary: '',
    },
  });

  const age = watch('age');
  const height = watch('height');
  const weight = watch('weight');

  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setValue('calendar', date?.toISOString() || '');
    if (date) {
      clearErrors('calendar');
    }
  };

  const onSubmit = (data: FormValues) => {
    if (!selectedDate) {
      setError('calendar', { type: 'manual', message: 'Please select a date' });
      return;
    }
    navigate('/talent/create-profile-3');
    console.log('Form data:', data);
  };

  const handleGenderClick = (gender: string) => {
    setGenderActive(gender);
    setValue('gender', gender);
  };

  return (
    <section className={styles.createProfileJobApplication}>
      <ProgressBar activeSteps={2} formSteps={5} />
      <img className={styles.img} width={231} height={77} src={passportImg} alt='Create profile' />
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <h3 className={styles.title}>Gender:</h3>
        <aside className={styles.genderBtnBox}>
          <label className={`${styles.genderBtn} ${genderActive === 'male' && styles.genderSelection}`}>
            <input
              className={styles.createProfileInput}
              placeholder='Male'
              type='radio'
              value='male'
              {...register('gender', { required: 'Gender is required' })}
              onClick={() => handleGenderClick('male')}
              checked={genderActive === 'male'}
              hidden
            />
            Male
          </label>

          <label className={`${styles.genderBtn} ${genderActive === 'female' && styles.genderSelection}`}>
            <input
              className={styles.createProfileInput}
              placeholder='Female'
              type='radio'
              value='female'
              {...register('gender', { required: 'Gender is required' })}
              onClick={() => handleGenderClick('female')}
              checked={genderActive === 'female'}
              hidden
            />
            Female
          </label>
        </aside>
        {errors.gender && <p className={styles.errorMessage}>{errors.gender.message}</p>}
        <label>
          <span className={styles.parametersBox}>
            <span>Age</span>
            <span>{age}</span>
          </span>
          <input
            className={styles.inputRange}
            type='range'
            min='1'
            max='100'
            {...register('age', { required: true, min: 1 })}
          />
        </label>
        {errors.age && <p className={styles.errorMessage}>Age is required and must be at least 1.</p>}

        <label>
          <span className={styles.parametersBox}>
            <span>Height</span>
            <span>{height}</span>
          </span>
          <input
            className={styles.inputRange}
            type='range'
            min='1'
            max='200'
            {...register('height', { required: true })}
          />
        </label>

        <label>
          <span className={styles.parametersBox}>
            <span>Weight</span>
            <span>{weight}</span>
          </span>
          <input
            className={styles.inputRange}
            type='range'
            min='1'
            max='150'
            {...register('weight', { required: true })}
          />
        </label>

        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder='Appearance'
            value={selectedCountry}
            onClick={() => setIsAppearancePopupOpen(true)}
            readOnly
            {...register('appearance', { required: 'Appearance is required' })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.appearance && typeof errors.appearance.message === 'string' && (
          <p className={styles.errorMessage}>{errors.appearance.message}</p>
        )}

        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder='Language skills'
            value={selectedLanguage}
            onClick={() => setIsLanguagePopupOpen(true)}
            readOnly
            {...register('languageSkills', { required: 'Language skills is required' })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.languageSkills && typeof errors.languageSkills.message === 'string' && (
          <p className={styles.errorMessage}>{errors.languageSkills.message}</p>
        )}

        <h3 className={styles.title}>Work experience:</h3>
        <textarea
          className={styles.workExperience}
          placeholder='Where have you worked? What position did you work in? How long did you work in that position?'
          {...register('workExperience', { required: 'Work experience is required' })}
        />
        {errors.workExperience && <p className={styles.errorMessage}>{errors.workExperience.message}</p>}
        <h3 className={styles.title}>About yourself:</h3>
        <textarea
          className={styles.aboutYourself}
          placeholder='Hello! My name is Anna and I am very interested in the vacancy you are offering. I believe that my skills and work experience help me successfully complete assigned tasks and achieve good results. I am highly organized and have the ability to work effectively in a team.'
          {...register('aboutYourself', { required: 'About yourself is required' })}
        />
        {errors.aboutYourself && <p className={styles.errorMessage}>{errors.aboutYourself.message}</p>}
        <h3 className={styles.title}>More details:</h3>

        <input
          className={styles.createProfileInput}
          type='text'
          name='location'
          placeholder='Your location'
          {...register('location', { required: 'Your location is required' })}
        />
        {errors.location && <p className={styles.errorMessage}>{errors.location.message}</p>}
        <p className={styles.desc}>Indicate the country, city in English.</p>
        <label className={styles.calendarBox}>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat='dd/MM/yyyy'
            className={styles.createProfileInput}
            placeholderText='When are you ready to start the contract?'
          />
          <img className={styles.calendarImg} width={30} height={30} src={calendarImg} alt='calendar' />
        </label>
        {errors.calendar && <p className={styles.errorMessage}>{errors.calendar.message}</p>}
        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder='Desired contract country'
            value={selectedCountryForWork}
            onClick={() => setIsCountryPopupOpen(true)}
            readOnly
            {...register('contractCountry', { required: 'Contract country is required' })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.contractCountry && <p className={styles.errorMessage}>{errors.contractCountry.message}</p>}
        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder='Desired contract period'
            value={selectedContractPeriod}
            onClick={() => setIsContractPeriodPopupOpen(true)}
            readOnly
            {...register('contractPeriod', { required: 'Contract period is required' })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.contractPeriod && <p className={styles.errorMessage}>{errors.contractPeriod.message}</p>}
        <label className={styles.labelBox}>
          <input
            className={styles.createProfileInput}
            placeholder='Expected Salary'
            value={selectedExpectedSalary}
            onClick={() => setIsExpectedSalaryPopupOpen(true)}
            readOnly
            {...register('expectedSalary', { required: 'Expected Salary is required' })}
          />
          <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
        </label>
        {errors.expectedSalary && <p className={styles.errorMessage}>{errors.expectedSalary.message}</p>}
        <CreateProfileNavigation backLink='/talent/create-profile-3' />
      </form>
      <PopUpCreateProfile
        title='Appearance'
        popupOpen={isAppearancePopupOpen}
        setPopupOpen={setIsAppearancePopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountry(value);
          setValue('appearance', value);
        }}
        selectedObject={selectedCountry}
        itemArray={countries}
      />
      <PopUpCreateProfile
        title='Language skills'
        popupOpen={isLanguagePopupOpen}
        setPopupOpen={setIsLanguagePopupOpen}
        setSelectedObject={(value) => {
          setSelectedLanguage(value);
          setValue('languageSkills', value);
        }}
        selectedObject={selectedLanguage}
        itemArray={languages}
      />
      <PopUpCreateProfile
        title='Desired country for work'
        popupOpen={isCountryPopupOpen}
        setPopupOpen={setIsCountryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountryForWork(value);
          setValue('contractCountry', value);
        }}
        selectedObject={selectedCountryForWork}
        itemArray={countriesForWorkArray}
      />
      <PopUpCreateProfile
        title='Desired contract period'
        popupOpen={isContractPeriodPopupOpen}
        setPopupOpen={setIsContractPeriodPopupOpen}
        setSelectedObject={(value) => {
          setSelectedContractPeriod(value);
          setValue('contractPeriod', value);
        }}
        selectedObject={selectedContractPeriod}
        itemArray={contractPeriodArray}
      />
      <PopUpCreateProfile
        title='Expected Salary'
        popupOpen={isExpectedSalaryPopupOpen}
        setPopupOpen={setIsExpectedSalaryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedExpectedSalary(value);
          setValue('expectedSalary', value);
        }}
        selectedObject={selectedExpectedSalary}
        itemArray={expectedSalaryArray}
      />
    </section>
  );
};

export default CreateProfileJobApplication;
