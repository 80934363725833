import React from 'react';
import FooterHomePage from '../../components/FooterHomePage';

import styles from './aboutUs.module.scss';

const aboutUsArray = [
  `PerformX is the ultimate platform for entertainers seeking job opportunities and employers searching for talent. We foster comfortable, reliable, and professional relationships for our users.`,
  `Our service connects you with high-paying jobs worldwide, expanding your range of opportunities.`,
  `At PerfomX, we prioritize transparency in communication and implement strict moderation to eliminate fakes and scammers, saving you time and ensuring you find suitable candidates.`,
  `With PerfomX, there are no hidden fees or automatic debits. You only pay for services after your confirmation. Join PerfomX and transform your career today!`,
];

const AboutUs = () => {
  return (
    <div className={styles.aboutUs}>
      <header className={styles.header}>
        <h1 className={styles.title}>About Us</h1>
      </header>
      <main className={styles.descContainer}>
        {aboutUsArray.map((item,index) => (
          <p className={styles.desc} key={index}>{item}</p>
        ))}
      </main>
      <FooterHomePage footer='' />
    </div>
  );
};

export default AboutUs;
