import React from 'react';
import FooterHomePage from '../../components/FooterHomePage';

import styles from './privacyPolicy.module.scss';

const faqArray = [
  {
    title: 'General Provisions',
    desc: `Under the terms of this User Agreement (hereinafter - the "Agreement"), the company DORNOOM LP (hereinafter - the "Company") provides users of the PerfomX application (hereinafter - "Denim") with the right to use the Denim services. The Denim application (hereinafter - the "Site") is exclusively a platform for communication. Denim users must adhere to the rules of this Agreement, as well as the Privacy Policy (hereinafter - the "Policy"). A User is a natural person who has reached the age of 18 (eighteen) years, accepts the terms of the Agreement, has full legal capacity, performs actions of their own free will and on their own behalf, and has confirmed their identity during registration. The Agreement enters into force from the moment the User agrees to all the terms of the Agreement. The User is considered to have accepted the terms of the Agreement in full after completing the registration procedure. User registration on the Site is an expression of written consent to all the terms of the Agreement and confirmation of the conclusion of a service agreement between the Company and the User on the terms of the Agreement. The current version of the Agreement is always available at http://denim-app.ru/terms-of-use. PerfomX management reserves the right to make changes to this Agreement without any special notice to the User. The new version of the Agreement enters into force from the moment of its approval by the Company and is considered brought to the attention of the User from the moment of its publication on the Site. If the Company has made changes to the Agreement, including changes to the name of the Services and/or their cost, with which the User does not agree, the User must stop using the service. This Agreement is not a public offer of the Company and cannot be interpreted as establishing agency or partnership relations between the User and the Company.`,
  },
  { title: ' General Provisions', desc: `Under the terms....` },
];

const PrivacyPolicy = () => {
  return (
    <div className={styles.faq}>
      <h1 className={styles.title}>Privacy Policy</h1>
      <h2 className={styles.title}>Privacy Policy</h2>
      <h2 className={styles.highlightedTitle}>User Agreement</h2>
      <ol className={styles.question}>
        {faqArray.map((item, index) => (
          <li key={index} className={styles.questionTitle}>
            <h5 className={styles.itemTitle}>{item.title}</h5>
            <p className={styles.itemDesc}>{item.desc}</p>
          </li>
        ))}
      </ol>
      <FooterHomePage footer='' />
    </div>
  );
};

export default PrivacyPolicy;
