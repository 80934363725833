import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import ProgressBar from '../../../components/Talent/ProgressBar';
import CreateProfileNavigation from '../../../components/Talent/CreateProfileNavigation/CreateProfileNavigation';
import morePhotoImg from '../../../img/Ctrate-form/more_photos.svg';
import defaultPhoto from '../../../img/Ctrate-form/default-photo_2.jpg';
import crossClose from '../../../img/Ctrate-form/cross-close.svg';

import styles from './createProfileMorePhotos.module.scss';

const CreateProfileMorePhotos = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [uploadedPhotos, setUploadedPhotos] = useState(Array(8).fill(null));
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log(data);
    navigate('/talent/create-profile-4');
    // navigate('/talent/congratulations-job-application');
  };

  const handlePhotoChange = (index) => (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newPhotos = [...uploadedPhotos];
        newPhotos[index] = e.target.result;
        setUploadedPhotos(newPhotos);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handlePhotoRemove = (index) => () => {
    const newPhotos = [...uploadedPhotos];
    newPhotos[index] = null;
    setUploadedPhotos(newPhotos);
  };

  return (
    <section className={styles.createProfileVerification}>
      <ProgressBar activeSteps={3} formSteps={5} />
      <img className={styles.img} width={231} height={77} src={morePhotoImg} alt='Create profile' />
      <p className={styles.desc}>Increase your chances of success.</p>
      <p className={styles.desc}>
        Please post eight or more videos. Include a video of yourself speaking English and introducing yourself. And a
        video of yourself doing some type of catwalk
      </p>

      <Link to='/talent/create-profile-5/photo-placement-rules' className={`${styles.title} ${styles.important}`}>
        Photo Placement Rules
      </Link>
      <h3 className={styles.title}>Post photos and videos:</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className={styles.formContainer}>
          {uploadedPhotos.map((photo, index) => (
            <li key={index} className={styles.imgBox}>
              <label className={styles.uploadPhoto} htmlFor={`photoInput-${index}`}>
                <img width={80} height={80} src={photo || defaultPhoto} alt={`uploaded photo ${index + 1}`} />
              </label>
              {photo && (
                <button type='button' className={styles.removeButton} onClick={handlePhotoRemove(index)}>
                  <img width={16} height={16} src={crossClose} alt='cross close' />
                </button>
              )}
              <input
                id={`photoInput-${index}`}
                type='file'
                accept='image/*'
                {...register(`photo${index}`, { required: index === 0 })}
                onChange={handlePhotoChange(index)}
                style={{ display: 'none' }}
              />
              {errors[`photo${index}`] && <span className={styles.errorMessage}>Please upload a photo</span>}
            </li>
          ))}
        </ul>
        <p className={styles.desc}>
          Beautiful photos and videos attract attention and increase  your chances of success!
        </p>
        <CreateProfileNavigation backLink='/talent/create-profile-2' />
      </form>
    </section>
  );
};

export default CreateProfileMorePhotos;
