import React from 'react';

import styles from './photoPlacementRules.module.scss';
import {Link} from "react-router-dom";

const placementRulesArray = [
  { desc: 'Images depicting the owner of the profile' },
  {
    desc: 'The face is clearly and fully visible',
  },
  {
    desc: 'The photos are of good quality, without blurring, noise and overexposure',
  },
];

const photosNotAllowedArray = [
  { desc: 'Images that are erotic, political, religious in nature' },
  {
    desc: 'With third parties (group photos), including children',
  },
  {
    desc: 'Cropped photos (for example, only parts of the body)',
  },
  {
    desc: 'Containing scenes of violence, weapons, and illegal activities',
  },
  {
    desc: 'Containing offensive content',
  },
  {
    desc: 'Promoting drug use, alcohol consumption, and smoking',
  },
  {
    desc: 'Containing advertisements',
  },
  {
    desc: 'Violating the above-mentioned rules',
  },
];

const PhotoPlacementRules = () => {
  return (
    <section className={styles.photoPlacementRules}>
      <h1 className={styles.title}>Photo Placement Rules</h1>
      <h2 className={styles.subTitle}>Photos allowed:</h2>
      <ul className={styles.welcomeItems}>
        {placementRulesArray.map((item, index) => (
          <li className={styles.welcomeItem} key={index}>
            <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M2 6.36842L6.03333 11L13 3' stroke='#219653' strokeWidth='2.5' strokeLinecap='round' />
            </svg>
            <p className={styles.welcomeItemDesc}>{item.desc}</p>
          </li>
        ))}
      </ul>
      <h2 className={styles.subTitle}>Photos that are not allowed:</h2>
      <ul className={styles.welcomeItems}>
        {photosNotAllowedArray.map((item, index) => (
          <li className={styles.welcomeItem} key={index}>
            <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M2 6.36842L6.03333 11L13 3' stroke='#219653' strokeWidth='2.5' strokeLinecap='round' />
            </svg>
            <p className={styles.welcomeItemDesc}>{item.desc}</p>
          </li>
        ))}
      </ul>
      <p className={styles.desc}>The administration reserves the right to refuse photo publication without providing reasons</p>
      <Link className={styles.link} to='/talent/create-profile-5'>Ok</Link>
    </section>
  );
};

export default PhotoPlacementRules;
