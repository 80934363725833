export const employerProfileObject = {
  id: '111',
  businessType: 'Agency',
  businessName: 'SV Models',
  block: [{ idTalent: '123', block: true }],
  rating: 4.96,
  iconEmployer: '',
  get numberOfReviews() {
    return this.reviews.length;
  },
  online: 'a day ago' /*логика вычисления времени как давно был заказчик*/,
  registeredOnSite: '25.05.2022',
  signedContracts: 10,
  documentsVerified: true,
  arbitration: 5,
  ratingEmployer: 4.2,
  favoriteEmployer: true,
  chat: [
    {
      idChat: '741',
      chat: [
        {
          idTalent: '1258',
          messagesTalent: 'Hi',
          messagesEmployer: 'Hello'
        },
      ],
    },
  ],
  jobs: [
    {
      id: '2231',
      photoPlaceWork: [
        { id: '123', url: '' },
        { id: '231', url: '' },
        { id: '321', url: '' },
      ],
      top: 'Top',
      applicants: '3',
      favorites: true,
      countryJobPosting: 'Malta',
      city: 'Valletta',
      placeWork: 'Club',
      vacancies: 'Dance Group',
      gender: 'Female',
      age: '25',
      height: '172',
      appearance: 'European',
      salary: '3000',
      salaryBonuses: '% of salary',
      paymentSchedule: 'Every 10 days',
      languageSkills: ['Chinese', 'French'],
      workingHours: { from: '22:00', to: '08:00' },
      break: { from: '22:00', to: '08:00' },
      dayOff: 'Every 10 days',
      contractStartDate: '2024-02-24T00:00:00Z',
      contractDuration: '30',
      theCompanyProvides: 'Visa',
      accommodation: '2 people',
      meals: 'Twice a day',
      cuisine: 'Asian',
      // ------------- несовпадает по макету ------------------
      airTicket: '',
      airportPickup: '',
      transferToAndFromWork: '',
      costumesUniform: '',
      professionalTraining: '',
    },
  ],
  reviews: [{}, {}, {}, {}],
};
