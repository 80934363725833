import React from 'react';
import { Link } from 'react-router-dom';
import styles from './linkButton.module.scss';

const LinkButton = ({ title, link }) => {
  return (
    <Link className={styles.linkButton} to={link}>
      {title}
    </Link>
  );
};

export default LinkButton;
