import React from 'react';
import styles from './createProfileNavigation.module.scss';
import { Link } from 'react-router-dom';
import backArrow from '../../../img/Ctrate-form/back_arrow.svg';
import nextArrow from '../../../img/Ctrate-form/next_arrow.svg';

const CreateProfileNavigation = ({ backLink }) => {
  return (
    <nav className={styles.nav}>
      <Link className={styles.navBox} to={backLink}>
        <img width={50} height={50} src={backArrow} alt='back arrow' />
        <span className={styles.navBtn}>Back</span>
      </Link>
      <button className={styles.navBox} type='submit'>
        <img width={50} height={50} src={nextArrow} alt='next arrow' />
        <span className={styles.navBtn}>Next</span>
      </button>
    </nav>
  );
};

export default CreateProfileNavigation;
