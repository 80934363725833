import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FieldError } from 'react-hook-form';
import PopUpCreateProfile from '../../../components/Talent/PopUpCreateProfile';
import CreateProfileNavigation from '../../../components/Talent/CreateProfileNavigation/CreateProfileNavigation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ProgressBarEmployer from '../../../components/Employer/ProgressBarEmployer';
import formPostJob from '../../../img/Create-form-employer/form_post_job.svg';
import arrow from '../../../img/Ctrate-form/arrow-down.svg';
import calendarImg from '../../../img/Ctrate-form/calendar.svg';
import watchImg from '../../../img/Create-form-employer/watch.svg';
import saveBtn from '../../../img/Create-form-employer/save_btn.svg';

import styles from './formPostJob.module.scss';
import Input from '../../../components/Input';

const regions = ['Asian', 'European', 'Eastern', 'African', 'Indian'];
const cities = ['Tbilisi', 'Kutaisi', 'Zugdidi', 'Bukhara', 'Namangan', 'Taraz', 'Bălți', 'Brest'];
const placeWork = ['Company', 'Club', 'KTV', 'Hotel', 'SPA', 'Party'];
const paymentScheduleArray = ['Once a day', 'Every 10 days', 'Once a week', 'Bi-Weekly', 'Once a month'];
const vacanciesArray = [
  'Go-go Dancer',
  'Dance Group',
  'Choreographer',
  'Strip Dancer',
  'Photo Model',
  'KTV Model',
  'Image Model',
  'Crypto Model',
  'Hostess',
];
const languages = ['Chinese', 'French', 'English'];
const countriesForWorkArray = ['Ukraine', 'Russia', 'Belarus', 'Moldova', 'Kazakhstan', 'Georgia', 'Uzbekistan'];
const cuisineArray = ['European', 'Asian', 'Eastern'];
const mealsArray = ['Once a day', 'Twice a day', 'Three times a day', 'Not provided'];
const accommodationArray = ['1 person', '2 people', '3-4 people', 'Not provided'];
const contractDurationArray = ['1 Month', 'From 2 to 3 months', 'Up to 6 months', 'Up to 1 year'];
const companyProvidesArray = [
  'Airplane ticket',
  'Visa',
  'Airport transfer',
  'Transfer to and from work',
  'Costumes / Uniform',
  'Professional training',
  'Gym',
];
const expectedSalaryArray = [
  'From $1000 to $2000',
  'From $2000 to $3000',
  'From $3000 to $4000',
  'From $4000 to $5000',
  'From $5000 to $6000',
  'From $6000 to $8000',
];

interface FormValues {
  age: number;
  height: number;
  weight: number;
  gender: string;
  appearance: string;
  languageSkills: string;
  calendar: string;
  countryJobPosting: string;
  expectedSalary: string;
  city: string;
  placeWork: string;
  vacancies: string;
  salaryBonuses: string;
  paymentSchedule: string;
  withoutBreak: string;
  contractDuration: string;
  workingHours: {
    from: string;
    to: string;
  };
  workingBreak: {
    from: string;
    to: string;
  };
  companyProvides: string;
  accommodation: string;
  meals: string;
  cuisine: string;
}

const FormPostJob = () => {
  const [withoutBreakActive, setWithoutBreakActive] = useState(true);

  const [genderActive, setGenderActive] = React.useState('');
  const [selectedCountry, setSelectedCountry] = React.useState('');
  const [isAppearancePopupOpen, setIsAppearancePopupOpen] = React.useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState('');
  const [isLanguagePopupOpen, setIsLanguagePopupOpen] = React.useState(false);

  const [isCountryPopupOpen, setIsCountryPopupOpen] = React.useState(false);
  const [selectedCountryForWork, setSelectedCountryForWork] = React.useState('');

  const [isCityPopupOpen, setIsCityPopupOpen] = React.useState(false);
  const [selectedCity, setSelectedCity] = React.useState('');

  const [isExpectedSalaryPopupOpen, setIsExpectedSalaryPopupOpen] = React.useState(false);
  const [selectedExpectedSalary, setSelectedExpectedSalary] = React.useState('');

  const [isPlaceWorkPopupOpen, setIsPlaceWorkPopupOpen] = React.useState(false);
  const [selectedPlaceWork, setSelectedPlaceWork] = React.useState('');

  const [isVacanciesPopupOpen, setIsVacanciesPopupOpen] = React.useState(false);
  const [selectedVacancies, setSelectedVacancies] = React.useState('');

  const [isPaymentSchedulePopupOpen, setIsPaymentSchedulePopupOpen] = React.useState(false);
  const [selectedPaymentSchedule, setSelectedPaymentSchedule] = React.useState('');

  const [isContractDurationPopupOpen, setIsContractDurationPopupOpen] = React.useState(false);
  const [selectedContractDuration, setSelectedContractDuration] = React.useState('');

  const [isCompanyProvidesPopupOpen, setIsCompanyProvidesPopupOpen] = React.useState(false);
  const [selectedCompanyProvides, setSelectedCompanyProvides] = React.useState('');

  const [isAccommodationPopupOpen, setIsAccommodationPopupOpen] = React.useState(false);
  const [selectedAccommodation, setSelectedAccommodation] = React.useState('');

  const [isMealsPopupOpen, setIsMealsPopupOpen] = React.useState(false);
  const [selectedMeals, setSelectedMeals] = React.useState('');

  const [isCuisinePopupOpen, setIsCuisinePopupOpen] = React.useState(false);
  const [selectedCuisine, setSelectedCuisine] = React.useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm<FormValues>({
    defaultValues: {
      age: 25,
      height: 165,
      weight: 55,
      gender: '',
      appearance: '',
      languageSkills: '',
      calendar: '',
      countryJobPosting: '',
      expectedSalary: '',
      city: '',
      placeWork: '',
      vacancies: '',
      salaryBonuses: '',
      paymentSchedule: '',
      withoutBreak: '',
      contractDuration: '',
      workingHours: { from: '', to: '' },
      workingBreak: { from: '', to: '' },
      companyProvides: '',
      accommodation: '',
      meals: '',
      cuisine: '',
    },
  });

  const age = watch('age');
  const height = watch('height');
  const weight = watch('weight');

  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    setValue('calendar', date?.toISOString() || '');
    if (date) {
      clearErrors('calendar');
    }
  };

  const handleGenderClick = (gender: string) => {
    setGenderActive(gender);
    setValue('gender', gender);
  };

  const [isTimePickerOpen, setIsTimePickerOpen] = useState<null | 'from' | 'to'>(null);
  const [workingHours, setWorkingHours] = useState<{ from: string; to: string }>({
    from: '',
    to: '',
  });

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const handleTimeChange = (field: 'from' | 'to', hours: number, minutes: number) => {
    const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    setWorkingHours((prev) => ({ ...prev, [field]: time }));
    setValue(`workingHours.${field}`, time);
    setIsTimePickerOpen(null);
  };

  const [isWorkingBreakOpen, setIsWorkingBreakOpen] = useState<null | 'from' | 'to'>(null);
  const [workingBreak, setWorkingBreak] = useState<{ from: string; to: string }>({
    from: '',
    to: '',
  });

  const [hoursWorkingBreak, setHoursWorkingBreak] = useState(0);
  const [minutesWorkingBreak, setMinutesWorkingBreak] = useState(0);

  const handleTimeChangeWorkingBreak = (
    field: 'from' | 'to',
    hoursWorkingBreak: number,
    minutesWorkingBreak: number
  ) => {
    const time = `${hoursWorkingBreak.toString().padStart(2, '0')}:${minutesWorkingBreak.toString().padStart(2, '0')}`;
    setWorkingBreak((prev) => ({ ...prev, [field]: time }));
    setValue(`workingBreak.${field}`, time);
    setIsWorkingBreakOpen(null);
  };

  const onSubmit = (data: FormValues) => {
    if (!selectedDate) {
      setError('calendar', { type: 'manual', message: 'Please select a date' });
      return;
    }
    navigate('/employer/create-profile-3');
    console.log('Form data:', data);
  };

  return (
    <section className={styles.formPostJob}>
      <ProgressBarEmployer activeSteps={2} formSteps={5} />
      <img className={styles.img} width={231} height={77} src={formPostJob} alt='Create profile' />
      <p className={styles.subTitle}>
        Complete the questionnaire to post your vacancy, receive applications, and choose the best candidates.
      </p>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          errors={errors}
          value={selectedCountryForWork}
          popUpOpen={() => setIsCountryPopupOpen(true)}
          register={register}
          fieldName='countryJobPosting'
          placeholder='Country of Job Posting'
          errorMassage='Contract country is required'
        />
        <Input
          errors={errors}
          value={selectedCity}
          popUpOpen={() => setIsCityPopupOpen(true)}
          register={register}
          fieldName='city'
          placeholder='City'
          errorMassage='City is required'
        />
        <Input
          errors={errors}
          value={selectedPlaceWork}
          popUpOpen={() => setIsPlaceWorkPopupOpen(true)}
          register={register}
          fieldName='placeWork'
          placeholder='Place of Work'
          errorMassage='Place of Work is required'
        />
        <Input
          errors={errors}
          value={selectedVacancies}
          popUpOpen={() => setIsVacanciesPopupOpen(true)}
          register={register}
          fieldName='vacancies'
          placeholder='Vacancies'
          errorMassage='Vacancies is required'
        />
        <h3 className={styles.title}>Candidate Requirements</h3>
        <h3 className={styles.title}>
          <span>Gender:</span>
        </h3>
        <article className={styles.genderBtnBox}>
          <label className={`${styles.genderBtn} ${genderActive === 'male' && styles.genderSelection}`}>
            <input
              className={styles.createProfileInput}
              placeholder='Male'
              type='radio'
              value='male'
              {...register('gender', { required: 'Gender is required' })}
              onClick={() => handleGenderClick('male')}
              checked={genderActive === 'male'}
              hidden
            />
            Male
          </label>

          <label className={`${styles.genderBtn} ${genderActive === 'female' && styles.genderSelection}`}>
            <input
              className={styles.createProfileInput}
              placeholder='Female'
              type='radio'
              value='female'
              {...register('gender', { required: 'Gender is required' })}
              onClick={() => handleGenderClick('female')}
              checked={genderActive === 'female'}
              hidden
            />
            Female
          </label>
        </article>
        <article className={styles.parameters}>
          {errors.gender && <p className={styles.errorMessage}>{errors.gender.message}</p>}
          <label>
            <span className={styles.parametersBox}>
              <span>Age</span>
              <span>{age}</span>
            </span>
            <input
              className={styles.inputRange}
              type='range'
              min='1'
              max='100'
              {...register('age', { required: true, min: 1 })}
            />
          </label>
          {errors.age && <p className={styles.errorMessage}>Age is required and must be at least 1.</p>}
          <label>
            <span className={styles.parametersBox}>
              <span>Height</span>
              <span>{height}</span>
            </span>
            <input
              className={styles.inputRange}
              type='range'
              min='1'
              max='200'
              {...register('height', { required: true })}
            />
          </label>
          <label>
            <span className={styles.parametersBox}>
              <span>Weight</span>
              <span>{weight}</span>
            </span>
            <input
              className={styles.inputRange}
              type='range'
              min='1'
              max='150'
              {...register('weight', { required: true })}
            />
          </label>
        </article>

        <Input
          errors={errors}
          value={selectedCountry}
          popUpOpen={() => setIsAppearancePopupOpen(true)}
          register={register}
          fieldName='appearance'
          placeholder='Appearance'
          errorMassage='Appearance is required'
        />

        <Input
          errors={errors}
          value={selectedLanguage}
          popUpOpen={() => setIsLanguagePopupOpen(true)}
          register={register}
          fieldName='languageSkills'
          placeholder='Language skills'
          errorMassage='Language skills is required'
        />

        <h3 className={styles.title}>Contract Terms  </h3>
        <h3 className={styles.title}>
          <span>Salary:</span>
        </h3>

        <Input
          errors={errors}
          value={selectedExpectedSalary}
          popUpOpen={() => setIsExpectedSalaryPopupOpen(true)}
          register={register}
          fieldName='expectedSalary'
          placeholder='Specify salary in USD'
          errorMassage='Specify Salary is required'
        />

        <textarea
          className={styles.workExperience}
          placeholder='Salary Bonuses
For example: % of salary, $ for
cocktails or table dances, or tips?'
          {...register('salaryBonuses', { required: 'Work experience is required' })}
        />
        {errors.salaryBonuses && <p className={styles.errorMessage}>{errors.salaryBonuses.message}</p>}

        <Input
          errors={errors}
          value={selectedPaymentSchedule}
          popUpOpen={() => setIsPaymentSchedulePopupOpen(true)}
          register={register}
          fieldName='paymentSchedule'
          placeholder='Payment Schedule'
          errorMassage='Payment Schedule is required'
        />

        <h3 className={styles.title}>
          <span>Working Hours:</span>
        </h3>
        <div className={styles.workingContainer}>
          <div className={styles.workingBox}>
            <label className={styles.labelBox}>
              <input
                className={styles.createProfileInput}
                type='text'
                placeholder='From:'
                value={workingHours.from}
                onClick={() => setIsTimePickerOpen('from')}
                readOnly
                {...register('workingHours.from', { required: 'From is required' })}
              />
              <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
              {errors?.workingHours?.from && <p className={styles.errorMessage}>{errors.workingHours.from.message}</p>}
            </label>

            <label className={styles.labelBox}>
              <input
                className={styles.createProfileInput}
                type='text'
                placeholder='To:'
                value={workingHours.to}
                onClick={() => setIsTimePickerOpen('to')}
                readOnly
                {...register('workingHours.to', { required: 'To is required' })}
              />
              <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
              {errors?.workingHours?.to && <p className={styles.errorMessage}>{errors.workingHours.to.message}</p>}
            </label>
          </div>

          {isTimePickerOpen && (
            <article className={styles.timePickerPopup}>
              <div className={styles.timePickerBox}>
                <img width={18} height={18} src={watchImg} alt='watch' />
                <p className={styles.title}>Time</p>
              </div>
              <div className={styles.timeBox}>
                <p className={styles.time}>{hours.toString().padStart(2, '0')}</p>
                <p className={styles.time}>:</p>
                <p className={styles.time}>{minutes.toString().padStart(2, '0')}</p>
              </div>
              <div className={styles.rangeContainer}>
                <div className={styles.rangeBox}>
                  <label>Hours:</label>
                  <input
                    className={styles.inputRange}
                    type='range'
                    min='0'
                    max='23'
                    value={hours}
                    onChange={(e) => setHours(Number(e.target.value))}
                  />
                </div>
                <div className={styles.rangeBox}>
                  <label>Minutes:</label>
                  <input
                    className={styles.inputRange}
                    type='range'
                    min='0'
                    max='59'
                    value={minutes}
                    onChange={(e) => setMinutes(Number(e.target.value))}
                  />
                </div>
              </div>

              <button className={styles.saveBtn} onClick={() => handleTimeChange(isTimePickerOpen, hours, minutes)}>
                <img width={12} height={10} src={saveBtn} alt='save btn' />
                <span>Save</span>
              </button>
            </article>
          )}
        </div>

        {withoutBreakActive && (
          <>
            <h3 className={styles.title}>
              <span>Break:</span>
            </h3>

            <div className={styles.workingContainer}>
              <div className={styles.workingBox}>
                <label className={styles.labelBox}>
                  <input
                    className={styles.createProfileInput}
                    type='text'
                    placeholder='From:'
                    value={workingBreak.from}
                    onClick={() => setIsWorkingBreakOpen('from')}
                    readOnly
                    {...register('workingBreak.from', { required: 'From is required' })}
                  />
                  <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
                  {errors?.workingBreak?.from && (
                    <p className={styles.errorMessage}>{errors.workingBreak.from.message}</p>
                  )}
                </label>

                <label className={styles.labelBox}>
                  <input
                    className={styles.createProfileInput}
                    type='text'
                    placeholder='To:'
                    value={workingBreak.to}
                    onClick={() => setIsWorkingBreakOpen('to')}
                    readOnly
                    {...register('workingBreak.to', { required: 'To is required' })}
                  />
                  <img className={styles.arrowImg} width={16} height={16} src={arrow} alt='arrow' />
                  {errors?.workingBreak?.to && <p className={styles.errorMessage}>{errors.workingBreak.to.message}</p>}
                </label>
              </div>

              {isWorkingBreakOpen && (
                <article className={styles.timePickerPopup}>
                  <div className={styles.timePickerBox}>
                    <img width={18} height={18} src={watchImg} alt='watch' />
                    <p className={styles.title}>Time</p>
                  </div>
                  <div className={styles.timeBox}>
                    <p className={styles.time}>{hoursWorkingBreak.toString().padStart(2, '0')}</p>
                    <p className={styles.time}>:</p>
                    <p className={styles.time}>{minutesWorkingBreak.toString().padStart(2, '0')}</p>
                  </div>
                  <div className={styles.rangeContainer}>
                    <div className={styles.rangeBox}>
                      <label>Hours:</label>
                      <input
                        className={styles.inputRange}
                        type='range'
                        min='0'
                        max='23'
                        value={hoursWorkingBreak}
                        onChange={(e) => setHoursWorkingBreak(Number(e.target.value))}
                      />
                    </div>
                    <div className={styles.rangeBox}>
                      <label>Minutes:</label>
                      <input
                        className={styles.inputRange}
                        type='range'
                        min='0'
                        max='59'
                        value={minutesWorkingBreak}
                        onChange={(e) => setMinutesWorkingBreak(Number(e.target.value))}
                      />
                    </div>
                  </div>

                  <button
                    className={styles.saveBtn}
                    onClick={() =>
                      handleTimeChangeWorkingBreak(isWorkingBreakOpen, hoursWorkingBreak, minutesWorkingBreak)
                    }>
                    <img width={12} height={10} src={saveBtn} alt='save btn' />
                    <span>Save</span>
                  </button>
                </article>
              )}
            </div>
          </>
        )}

        <label className={styles.checkBox}>
          <input
            type='checkbox'
            {...register('withoutBreak')}
            onClick={() => setWithoutBreakActive(!withoutBreakActive)}
          />
          <span className={styles.desc}>Without a break</span>
        </label>

        <label className={styles.calendarBox}>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat='dd/MM/yyyy'
            className={styles.createProfileInput}
            placeholderText='When are you ready to start the contract?'
          />
          <img className={styles.calendarImg} width={30} height={30} src={calendarImg} alt='calendar' />
        </label>
        {errors.calendar && <p className={styles.errorMessage}>{errors.calendar.message}</p>}

        <Input
          errors={errors}
          value={selectedContractDuration}
          popUpOpen={() => setIsContractDurationPopupOpen(true)}
          register={register}
          fieldName='contractDuration'
          placeholder='Contract Duration'
          errorMassage='Contract Duration is required'
        />

        <h3 className={styles.title}>Provided Conditions  </h3>
        <Input
          errors={errors}
          value={selectedCompanyProvides}
          popUpOpen={() => setIsCompanyProvidesPopupOpen(true)}
          register={register}
          fieldName='companyProvides'
          placeholder='The company provides'
          errorMassage='The company provides is required'
        />

        <Input
          errors={errors}
          value={selectedAccommodation}
          popUpOpen={() => setIsAccommodationPopupOpen(true)}
          register={register}
          fieldName='accommodation'
          placeholder='Accommodation'
          errorMassage='Accommodation provides is required'
        />

        <Input
          errors={errors}
          value={selectedMeals}
          popUpOpen={() => setIsMealsPopupOpen(true)}
          register={register}
          fieldName='meals'
          placeholder='Meals'
          errorMassage='Meals provides is required'
        />

        <Input
          errors={errors}
          value={selectedCuisine}
          popUpOpen={() => setIsCuisinePopupOpen(true)}
          register={register}
          fieldName='cuisine'
          placeholder='Cuisine'
          errorMassage='Cuisine provides is required'
        />

        <CreateProfileNavigation backLink='/employer/create-profile-1' />
      </form>
      <PopUpCreateProfile
        title='Desired country for work'
        popupOpen={isCountryPopupOpen}
        setPopupOpen={setIsCountryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountryForWork(value);
          setValue('countryJobPosting', value);
        }}
        selectedObject={selectedCountryForWork}
        itemArray={countriesForWorkArray}
      />

      <PopUpCreateProfile
        title='City'
        popupOpen={isCityPopupOpen}
        setPopupOpen={setIsCityPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCity(value);
          setValue('city', value);
        }}
        selectedObject={selectedCity}
        itemArray={cities}
      />

      <PopUpCreateProfile
        title='Place of Work'
        popupOpen={isPlaceWorkPopupOpen}
        setPopupOpen={setIsPlaceWorkPopupOpen}
        setSelectedObject={(value) => {
          setSelectedPlaceWork(value);
          setValue('placeWork', value);
        }}
        selectedObject={selectedPlaceWork}
        itemArray={placeWork}
      />

      <PopUpCreateProfile
        title='Payment Schedule'
        popupOpen={isPaymentSchedulePopupOpen}
        setPopupOpen={setIsPaymentSchedulePopupOpen}
        setSelectedObject={(value) => {
          setSelectedPaymentSchedule(value);
          setValue('paymentSchedule', value);
        }}
        selectedObject={selectedPaymentSchedule}
        itemArray={paymentScheduleArray}
      />

      <PopUpCreateProfile
        title='Vacancies'
        popupOpen={isVacanciesPopupOpen}
        setPopupOpen={setIsVacanciesPopupOpen}
        setSelectedObject={(value) => {
          setSelectedVacancies(value);
          setValue('vacancies', value);
        }}
        selectedObject={selectedVacancies}
        itemArray={vacanciesArray}
      />

      <PopUpCreateProfile
        title='Appearance'
        popupOpen={isAppearancePopupOpen}
        setPopupOpen={setIsAppearancePopupOpen}
        setSelectedObject={(value) => {
          setSelectedCountry(value);
          setValue('appearance', value);
        }}
        selectedObject={selectedCountry}
        itemArray={regions}
      />
      <PopUpCreateProfile
        title='Language skills'
        popupOpen={isLanguagePopupOpen}
        setPopupOpen={setIsLanguagePopupOpen}
        setSelectedObject={(value) => {
          setSelectedLanguage(value);
          setValue('languageSkills', value);
        }}
        selectedObject={selectedLanguage}
        itemArray={languages}
      />

      <PopUpCreateProfile
        title='Expected Salary'
        popupOpen={isExpectedSalaryPopupOpen}
        setPopupOpen={setIsExpectedSalaryPopupOpen}
        setSelectedObject={(value) => {
          setSelectedExpectedSalary(value);
          setValue('expectedSalary', value);
        }}
        selectedObject={selectedExpectedSalary}
        itemArray={expectedSalaryArray}
      />
      <PopUpCreateProfile
        title='Contract Duration'
        popupOpen={isContractDurationPopupOpen}
        setPopupOpen={setIsContractDurationPopupOpen}
        setSelectedObject={(value) => {
          setSelectedContractDuration(value);
          setValue('contractDuration', value);
        }}
        selectedObject={selectedContractDuration}
        itemArray={contractDurationArray}
      />
      <PopUpCreateProfile
        title='The company provides'
        popupOpen={isCompanyProvidesPopupOpen}
        setPopupOpen={setIsCompanyProvidesPopupOpen}
        setSelectedObject={(value) => {
          setSelectedCompanyProvides(value);
          setValue('companyProvides', value);
        }}
        selectedObject={selectedCompanyProvides}
        itemArray={companyProvidesArray}
      />
      <PopUpCreateProfile
        title='Accommodation'
        popupOpen={isAccommodationPopupOpen}
        setPopupOpen={setIsAccommodationPopupOpen}
        setSelectedObject={(value) => {
          setSelectedAccommodation(value);
          setValue('accommodation', value);
        }}
        selectedObject={selectedAccommodation}
        itemArray={accommodationArray}
      />

      <PopUpCreateProfile
        title='Meals'
        popupOpen={isMealsPopupOpen}
        setPopupOpen={setIsMealsPopupOpen}
        setSelectedObject={(value) => {
          setSelectedMeals(value);
          setValue('meals', value);
        }}
        selectedObject={selectedMeals}
        itemArray={mealsArray}
      />

      <PopUpCreateProfile
        title='Cuisine'
        popupOpen={isCuisinePopupOpen}
        setPopupOpen={setIsCuisinePopupOpen}
        setSelectedObject={(value) => {
          setSelectedCuisine(value);
          setValue('cuisine', value);
        }}
        selectedObject={selectedCuisine}
        itemArray={cuisineArray}
      />
    </section>
  );
};

export default FormPostJob;
