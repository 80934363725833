import React from 'react';
import Footer from '../../../components/Talent/Footer';
import { Link } from 'react-router-dom';
import backArrow from '../../../img/Boost-profile/back_arrow.svg';
import receiveNotifications from '../../../img/Boost-profile/receive_notifications.svg';

import styles from './receiveNotifications.module.scss';

const tariffsArray = [
  { desc: '7 days', subTitle: 28 },
  { desc: '1 month', subTitle: 88 },
  { desc: '14 days', subTitle: 48 },
];

const ReceiveNotifications = () => {
  return (
    <div className={styles.receiveNotifications}>
      <header className={styles.header}>
        <Link className={styles.link} to='/talent/profile'>
          <img width={32} height={32} src={backArrow} alt='back arrow' />
        </Link>
        <img className={styles.img} width={274} height={66} src={receiveNotifications} alt='back arrow' />
      </header>
      <p className={styles.desc}>
        Receive instant notification of new posted jobs that you filter and be the first to choose
      </p>
      <h2 className={styles.subTitle}>Choose a plan</h2>
      <section className={styles.tariffs}>
        {tariffsArray.map((item, index) => (
          <div className={styles.tariffsBox} key={index}>
            <p className={styles.tariffsDesc}>{item.desc}</p>
            <p className={styles.tariffsSubTitle}>$ {item.subTitle}</p>
          </div>
        ))}
      </section>
      <button className={styles.balance} type='button'>
        Top up balance
      </button>
      <p className={styles.funds}>Insufficient funds for purchase. Balance: 0</p>
      <Footer />
    </div>
  );
};

export default ReceiveNotifications;
