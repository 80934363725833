import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import backArrow from '../../../img/Boost-profile/back_arrow.svg';
import getBonuses from '../../../img/Profile/get_bonuses_title.svg';
import Footer from '../../../components/Talent/Footer';

import styles from './getBonuses.module.scss';

const GetBonuses = () => {
  const [referralLink, setReferralLink] = useState('');

  const generateReferralLink = () => {
    // сгенерировать уникальную ссылку
    const link = 'https://performx.com/referral/your-unique-id';
    setReferralLink(link);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink).then(() => {
      alert('Ссылка скопирована в буфер обмена');
    }).catch(() => {
      alert('Не удалось скопировать ссылку');
    });
  };

  return (
    <div className={styles.getBonuses}>
      <header className={styles.header}>
        <Link className={styles.link} to='/talent/profile'>
          <img width={32} height={32} src={backArrow} alt='back arrow' />
        </Link>
        <img className={styles.img} width={231} height={69} src={getBonuses} alt='boost profile' />
      </header>
      <main>
        <p className={styles.desc}>
          Invite friends using your URL link to join us at <b>PerformX</b> and receive bonuses.
        </p>
        <p className={styles.desc}>Get a subscription to post your profile on the service:</p>
        <ul className={styles.friends}>
          <li className={styles.friendsDesc}>
            For 3 friends, <b>get 7 days for free!</b>
          </li>
          <li className={styles.friendsDesc}>
            For 5 friends, <b>get 14 days for free!</b>
          </li>
          <li className={styles.friendsDesc}>
            For 8 friends, <b>get 30 days for free!</b>
          </li>
        </ul>

        <div className={styles.linkSection}>
          <input
            type='text'
            className={styles.input}
            value={referralLink}
            placeholder='Your URL link'
            readOnly
          />
          <button className={styles.copyBtn} onClick={copyToClipboard} disabled={!referralLink}>
            Copy
          </button>
        </div>

        <button className={styles.getButton} onClick={generateReferralLink}>
          Get URL
        </button>
      </main>
      <Footer />
    </div>
  );
};

export default GetBonuses;
